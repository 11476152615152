import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { OVCAlert, OVCInput, OVCAuthTrackOrderCTA } from 'outvio-ui';

import { getOTNFromIdentifier } from '../../utils/api';

const FormSchema = Yup.object().shape({
  orderIdentifier: Yup.string().required(),
  postcode: Yup.string().required(),
});

interface IDataInputPanel {
  onGotOTN(value: string): void;
}

const DataInputPanel = ({ onGotOTN }: IDataInputPanel) => {
  const intl = useIntl();

  const [error, setError] = useState<null | string>(null);

  return (
    <Formik
      validationSchema={FormSchema}
      initialValues={{
        orderIdentifier: '',
        postcode: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        getOTNFromIdentifier(values)
          .then((res) => {
            onGotOTN(res.otn);
          })
          .catch((err) => {
            setError(err.message || err.toString());
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <OVCInput
            placeholder={intl.formatMessage({
              id: 'ovc.auth.tracking.orderIdentifier.placeholder',
            })}
            name="orderIdentifier"
            onChange={handleChange}
            value={values.orderIdentifier}
            disabled={isSubmitting}
          />
          <OVCInput
            placeholder={intl.formatMessage({ id: 'ovc.auth.tracking.postcode.placeholder' })}
            name="postcode"
            onChange={handleChange}
            value={values.postcode}
            disabled={isSubmitting}
          />
          <OVCAuthTrackOrderCTA
            disabled={isSubmitting}
            showLoader={isSubmitting}
            type="submit"
            fullWidth
            style={{ marginTop: '8px' }}
          />

          <OVCAlert isOpen={error !== null}>{error}</OVCAlert>
        </form>
      )}
    </Formik>
  );
};

export default DataInputPanel;
