import { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { OVCLangSwitcherContext, OVCI18NProvider } from 'outvio-ui';
import { AVAILABLE_LANGUAGES } from './constants';
import Layout from './components/Common/Layout';
import LoginPage from './pages/LoginPage/LoginPage';
import PubTracking from './pages/PubTracking';
import OrdersListing from './pages/OrdersListingPage';
import IdentityVerificationPage from './pages/IdentityVerificationPage';
import Logout from './pages/LogoutPage';
import ImpersonateLogin from './pages/ImpersonateLoginPage';
import ReturnCart from './pages/ReturnCart/ReturnCartPage';
import SpecificTracking from './pages/SpecificTracking/SpecificTrackingPage';

import { RedirectIndexRoute, RedirectPubTrackingRoute } from './AppRoutes';
import { useLanguageRouter } from './hooks/useLanguageRouter';

const LanguageRouter = () => {
  const { appLang, handleLangChange, match } = useLanguageRouter();

  return (
    <OVCLangSwitcherContext.Provider value={{ appLang, changeLangTo: handleLangChange }}>
      <OVCI18NProvider>
        <Layout>
          <Switch>
            <Route exact path={`${match.url}/`} component={LoginPage} />
            <Route path={`${match.url}/my-orders`} component={OrdersListing} />
            <Route exact path={`${match.url}/returns/cart/:otn`} component={ReturnCart} />
            <Route exact path={`${match.url}/id/:otn`} component={PubTracking} />
            <Route exact path={`${match.url}/return`} component={IdentityVerificationPage} />
            <Route exact path={`${match.url}/logout`} component={Logout} />
            <Route exact path={`${match.url}/login/:token`} component={ImpersonateLogin} />
            <Route exact path={`${match.url}/tracking`} component={SpecificTracking} />
          </Switch>
        </Layout>
      </OVCI18NProvider>
    </OVCLangSwitcherContext.Provider>
  );
};

const RedirectPubReturn = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (location.search && location.search.substr(-5).length === 5) {
      history.push(`/en/return?token=${token}`);
    } else {
      history.push(`/es/return?token=${token}`);
    }
  }, []);

  return null;
};

const App = () => {
  return (
    <>
      <Switch>
        {RedirectPubTrackingRoute}
        <Route exact path="/return" component={RedirectPubReturn} />
        <Route path={`/:urlLang(${AVAILABLE_LANGUAGES.join('|')})`} component={LanguageRouter} />
        {RedirectIndexRoute}
      </Switch>
    </>
  );
};

export default App;
