import { Redirect, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AVAILABLE_LANGUAGES } from './constants';
import {
  SupportedLanguage,
  SupportedLanguage2LetterList,
  SupportedLanguages,
} from './types/general';
import { Location } from 'history';

const RedirectPubTracking = () => {
  const location = useLocation();
  const history = useHistory();
  const { otn } = useParams<{ otn: string }>();

  useEffect(() => {
    if (location.search && location.search.substr(-5).length === 5) {
      history.push(`/en/id/${otn}`);
    } else {
      history.push(`/es/id/${otn}`);
    }
  }, []);

  return null;
};

const findLocale = (location: Location, urlLangParam: string): SupportedLanguage => {
  if (urlLangParam) {
    switch (urlLangParam) {
      case 'es':
        return 'es-ES';
      case 'ca':
        return 'ca-ES';
      case 'et':
        return 'et-EE';
      case 'fr':
        return 'fr-FR';
      case 'it':
        return 'it-IT';
      case 'de':
        return 'de-DE';
      case 'fi':
        return 'fi-FI';
      case 'lt':
        return 'lt-LT';
      case 'lv':
        return 'lv-LV';
      case 'pt':
        return 'pt-PT';
      case 'pl':
        return 'pl-PL';
      case 'sv':
        return 'sv-SE';
      case 'da':
        return 'da-DK';
      case 'en':
      default:
        return 'en-EN';
    }
  }

  // If a locale is provided by the URL, use that ("es-ES" format)
  if (location.search && location.search.substr(-5).length === 5) {
    const urlLang = location.search.substr(-5);

    if (urlLang in SupportedLanguages) {
      return urlLang as SupportedLanguage;
    }
  }

  // Default to EN
  return 'en-EN';
};
const RedirectPubTrackingRoute = <Route exact path="/id/:otn" component={RedirectPubTracking} />;

const RedirectIndexRoute = (
  <Redirect
    from="/"
    to={`/${
      AVAILABLE_LANGUAGES.includes(navigator.language as SupportedLanguage2LetterList)
        ? navigator.language
        : 'en'
    }`}
  />
);

export { RedirectPubTrackingRoute, RedirectIndexRoute, findLocale };
