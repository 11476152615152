import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  OVCLoader,
  OVCAlert,
  OVCStoreDataContext,
  OVCLayoutContainer,
  OVCLayoutHeader,
  OVCFooterWatermark,
  OVCLangSwitcher,
} from 'outvio-ui';

import { makeStyles } from '@material-ui/core';

import { initApp } from '../../slices/app';

import { RootState } from '../../reducers';
import HeaderLogo from './HeaderLogo';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    width: '100%',
    height: '100vh',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: '32px 80px',
    minHeight: 'calc(100vh - 150px)',
    '@media (max-width: 1200px)': { padding: '32px 40px' },
    '@media (max-width: 640px)': { padding: '32px 5px' },
  },
  langSwitch: {
    position: 'absolute',
    top: '25px',
    right: '80px',

    [theme.breakpoints.down('xs')]: {
      right: '10px',
      top: '30px',
    },
  },
}));
interface ILayout {
  children: React.ReactNode;
  isTracking?: boolean;
}

const Layout = ({ children, isTracking }: ILayout) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isInitialized = useSelector((state: RootState) => state.app.isInitialized);
  const error = useSelector((state: RootState) => state.app.error);
  const storeData = useSelector((state: RootState) => state.app.storeData);
  const isIframed = window.location.href.includes('iframe=1');

  useEffect(() => {
    dispatch(initApp(isTracking));
  }, []);

  useEffect(() => {
    if (storeData && storeData.portalSettings) {
      if (!window.location.origin.endsWith('outvio.com') && storeData.portalSettings.favicon) {
        const faviconEl = document.getElementById('favicon');
        if (faviconEl) {
          (faviconEl as HTMLLinkElement).href = storeData.portalSettings.favicon;
        }
      }
      if (!window.location.origin.endsWith('outvio.com') && storeData.portalSettings.pageTitle) {
        document.title = storeData.portalSettings.pageTitle;
      }
    }
  }, [storeData]);

  if (error !== null) {
    return (
      <div className={classes.loaderContainer}>
        <OVCAlert>{error}</OVCAlert>
      </div>
    );
  }

  if (!isInitialized) {
    return (
      <div className={classes.loaderContainer}>
        <OVCLoader />
      </div>
    );
  }

  return (
    <OVCStoreDataContext.Provider value={{ storeData: storeData }}>
      <OVCLayoutContainer>
        {!isIframed && (
          <OVCLayoutHeader>
            <HeaderLogo />
            <OVCLangSwitcher />
          </OVCLayoutHeader>
        )}

        {children}
        {!isIframed && <OVCFooterWatermark />}
      </OVCLayoutContainer>
    </OVCStoreDataContext.Provider>
  );
};

export default Layout;
