import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { findLocale } from '../AppRoutes';
import OVLocalCache from 'utils/storage';
import { SupportedLanguage } from '../types/general';
import { LOCALE_TO_LANG } from '../constants';

export function useLanguageRouter() {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{ urlLang: string }>();

  const [appLang, setAppLang] = useState(findLocale(location, match.params.urlLang));

  useEffect(() => {
    const userLang = findLocale(location, match.params.urlLang);
    setAppLang(userLang);
    OVLocalCache.set('userLang', userLang);
  }, [match, location]);

  const handleLangChange = (value: SupportedLanguage) => {
    setAppLang(value);
    const currUrlSplit = window.location.pathname.split('/');
    currUrlSplit[1] = LOCALE_TO_LANG[value];

    history.replace(`${currUrlSplit.join('/')}`);
  };

  return { handleLangChange, appLang, match };
}
