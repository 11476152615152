const displaySupportError = () => {
  const C = document.createElement('div');
  C.setAttribute(
    'style',
    'width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; color: #00363d; font-size: 16px; text-align: center;',
  );
  const P_EN = document.createElement('p');
  P_EN.setAttribute('style', 'max-width: 300px');
  P_EN.appendChild(
    document.createTextNode(
      'Unsupported browser. Please update your browser or switch to an evergreen browser (Chrome, Firefox, Safari, etc.) and turn off private mode.',
    ),
  );
  C.appendChild(P_EN);

  const ROOT = document.getElementById('root');

  if (ROOT) {
    ROOT.appendChild(C);
  } else {
    throw new Error('Missing ROOT.');
  }
};

export default displaySupportError;
