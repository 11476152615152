import { CustomerReturnMethod, CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';

import ConfirmationToClientShop from './ConfirmationToClientShop';
import ConfirmationOwnCourier from './ConfirmationOwnCourier';
import ConfirmationExchange from './ConfirmationExchange';
import ReturnCartStep4 from './ReturnCartStep4';

import {
  ReturnWarehouse,
  ReturnLabelMode,
  StateProduct,
  Package,
  ReturnAddress,
} from './returnCartTypes';
import { OrderData } from '../../types/general';

interface IReturnCartStepConfirmation {
  returnMethod: null | CustomerReturnMethod;
  refundMethod: null | CustomerReimbursement;
  hasPickup: boolean;
  returnWarehouse: ReturnWarehouse;
  labelMode: ReturnLabelMode;
  productsReturning: StateProduct[];
  returnExchangeComment: string;
  order: OrderData;
  returnPackages: Package[];
  returnAddress: null | ReturnAddress;
}

const ReturnCartStepConfirmation = ({
  returnMethod,
  refundMethod,
  order,
  returnPackages,
  returnAddress,
  productsReturning,
  hasPickup,
  labelMode,
  returnWarehouse,
  returnExchangeComment,
}: IReturnCartStepConfirmation) => {
  return (
    <>
      {returnMethod === 'toClientShop' && (
        <ConfirmationToClientShop
          order={order}
          productsReturning={productsReturning}
          refundMethod={refundMethod}
        />
      )}
      {returnMethod === 'ownCourier' && (
        <ConfirmationOwnCourier
          order={order}
          productsReturning={productsReturning}
          returnWarehouse={returnWarehouse}
          refundMethod={refundMethod}
        />
      )}
      {returnMethod === 'exchange' && (
        <ConfirmationExchange
          order={order}
          productsReturning={productsReturning}
          refundMethod={refundMethod}
          returnExchangeComment={returnExchangeComment}
        />
      )}
      {returnMethod === 'outvio' && returnAddress !== null && (
        <ReturnCartStep4
          order={order}
          returnPackages={returnPackages}
          returnAddress={returnAddress}
          hasPickup={hasPickup}
          labelMode={labelMode}
          refundMethod={refundMethod}
        />
      )}
    </>
  );
};

export default ReturnCartStepConfirmation;
