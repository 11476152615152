import { SVGAttributes } from 'react';

interface IconProps extends SVGAttributes<SVGElement> {
  foreColor: string;
  bgColor: string;
}

const PackageIcon = ({ foreColor, bgColor, ...rest }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...rest}>
    <path d="M37.525 79.527L.472 63.47V14.678l37.054 16.06v48.791" fill={foreColor} />
    <path
      d="M37.527 79.527l.188-.43L.944 63.159V15.394l36.115 15.65v48.483h.468l.188-.43-.188.43h.474V30.736a.47.47 0 0 0-.285-.432L.659 14.244a.47.47 0 0 0-.656.432v48.797a.472.472 0 0 0 .284.432l37.055 16.058a.472.472 0 0 0 .659-.433h-.473"
      fill={bgColor}
    />
    <path d="M21.606 37.192l-4.15-4.942-4.148 1.45V20.05l8.3 3.493v13.648" fill={foreColor} />
    <path
      d="M21.607 37.193l.361-.3-4.15-4.942a.468.468 0 0 0-.517-.14l-3.522 1.229V20.761l7.357 3.1v13.335h.47l.361-.3-.361.3h.471V23.545a.474.474 0 0 0-.288-.435l-8.3-3.492a.47.47 0 0 0-.651.433v13.65a.467.467 0 0 0 .626.443l3.84-1.341 3.942 4.693a.471.471 0 0 0 .832-.3h-.47"
      fill={bgColor}
    />
    <path d="M37.526 79.527l40.148-14.2V16.529l-40.148 14.21V79.53" fill={foreColor} />
    <path
      d="M37.526 79.526l.158.446 40.146-14.2a.478.478 0 0 0 .316-.446v-48.8a.47.47 0 0 0-.629-.443L37.371 30.291a.469.469 0 0 0-.313.445v48.79a.469.469 0 0 0 .626.446l-.158-.446H38v-48.46l39.2-13.872v47.794l-39.833 14.1.155.443"
      fill={bgColor}
    />
    <path d="M41.233 73.351l8.973-3.173v-10.9l-8.973 3.173v10.9" fill={foreColor} />
    <path
      d="M41.235 73.351l.158.445 8.971-3.173a.474.474 0 0 0 .315-.445v-10.9a.474.474 0 0 0-.628-.445l-8.971 3.173a.477.477 0 0 0-.315.445v10.9a.469.469 0 0 0 .628.445l-.158-.445h.473V62.783l8.029-2.843v9.9l-8.658 3.064.155.443"
      fill={bgColor}
    />
    <path d="M52.782 69.442l8.97-3.174v-10.9l-8.97 3.174v10.9" fill={foreColor} />
    <path
      d="M52.783 69.437l.158.445 8.97-3.176a.473.473 0 0 0 .314-.443v-10.9a.47.47 0 0 0-.629-.445l-8.97 3.173a.471.471 0 0 0-.313.446v10.9a.468.468 0 0 0 .2.384.473.473 0 0 0 .43.061l-.158-.445h.47V58.871l8.029-2.842v9.9l-8.658 3.064.158.443"
      fill={bgColor}
    />
    <path d="M.472 14.673L40.615.473l37.059 16.058-40.148 14.207L.472 14.673" fill={foreColor} />
    <path
      d="M.473 14.676l.157.445L40.598.976l35.786 15.51-38.84 13.744L.66 14.244l-.188.432.157.445-.157-.445-.187.432 37.054 16.06a.456.456 0 0 0 .344.013l40.146-14.205a.47.47 0 0 0 .031-.875L40.803.041a.469.469 0 0 0-.344-.012L.313 14.233a.47.47 0 0 0-.029.875l.187-.432"
      fill={bgColor}
    />
    <path d="M13.308 20.016L53.454 5.81l8.3 3.529-40.146 14.206-8.3-3.529" fill={foreColor} />
    <path
      d="M13.308 20.015l.155.443L53.439 6.314l7.015 2.984-38.833 13.74-8.13-3.455-.184.432.155.443-.155-.443-.185.432 8.3 3.53a.464.464 0 0 0 .34.01L61.91 9.785a.471.471 0 0 0 .028-.878l-8.3-3.531a.47.47 0 0 0-.34-.01L13.152 19.571a.472.472 0 0 0-.313.43.466.466 0 0 0 .285.447l.185-.432"
      fill={bgColor}
    />
  </svg>
);

export default PackageIcon;
