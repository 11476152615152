import { useState, useEffect, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  OVCLoader,
  OVCAlert,
  OVCStoreDataContext,
  OVCReturnCartContainer,
  OVCButton,
  OVCReturnCartStepper,
  OVCReturnCartInnerContainer,
  OVCReturnCartClose,
} from 'outvio-ui';
import { CustomerReturnMethod, CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';
import isUndefined from 'lodash-es/isUndefined';

import { makeStyles } from '@material-ui/core';

import ReturnCartStep1 from './ReturnCartStep1';
import ReturnCartStepMethods from './ReturnCartStepMethods';
import ReturnCartStepInstructions from './ReturnCartStepInstructions';
import ReturnCartStepConfirmation from './ReturnCartStepConfirmation';
import {
  Package,
  StateProduct,
  PartialStateProduct,
  ReturnWarehouse,
  ReturnCourier,
  ReturnLabelMode,
  ReturnSavedPackagesObj,
  ReturnAddress,
} from './returnCartTypes';
import { Product, OrderData } from '../../types/general';

import {
  requestOrderByOtn,
  getReturnWarehouse,
  resetOrderByOTN,
  getReturnCourierOptions,
  getReturnAllowedMethods,
} from '../../utils/api';
import getUrlParamFromLocale from '../../utils/getUrlParamFromLocale';

const useStyles = makeStyles({
  errorRestart: {
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const sortProductsBySelected = (a: StateProduct, b: StateProduct) => {
  if (a.stateSelected && !b.stateSelected) {
    return -1;
  }
  if (!a.stateSelected && b.stateSelected) {
    return 1;
  }
  return 0;
};

const ReturnCartPage = () => {
  const history = useHistory();
  const intl = useIntl();

  const { storeData } = useContext(OVCStoreDataContext);

  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });
  const { otn } = useParams<{ otn?: string }>();

  const [page, setPage] = useState<number>(1);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [order, setOrder] = useState<OrderData | null>(null);
  const [returnWarehouse, setReturnWarehouse] = useState<null | ReturnWarehouse>(null);
  const [returnCouriers, setReturnCouriers] = useState<ReturnCourier[]>([]);

  const [productsReturning, setProductsReturning] = useState<StateProduct[]>([]);
  const [returnPackages, setReturnPackages] = useState<Package[]>([]);
  const [returnCost, setReturnCost] = useState(0);
  const [returnAddress, setReturnAddress] = useState<null | ReturnAddress>(null);
  const [returnMethod, setReturnMethod] = useState<null | CustomerReturnMethod>(null);
  const [availableReturnMethods, setAvailableReturnMethods] = useState<CustomerReturnMethod[]>([]);
  const [refundMethod, setRefundMethod] = useState<null | CustomerReimbursement>(null);
  const [hasPickup, setHasPickup] = useState(true);
  const [labelMode, setLabelMode] = useState<ReturnLabelMode>('provided');
  const [returnExchangeComment, setExchangeComment] = useState('');
  const [returnPickupMode, setReturnPickupMode] = useState<string | null>(null);

  const hasOneSimpleMethod = useMemo(
    () => availableReturnMethods.filter((m) => m !== 'exchange').length === 1,
    [availableReturnMethods],
  );
  const hasOneSimpleRefundMethod = storeData.return.reimbursement === 'SAME_AS_PAYMENT';
  const hasProductWithExchange = productsReturning.some(
    (p) =>
      p.returned &&
      p.returned.client &&
      p.returned.client.reason &&
      (
        (storeData.return.customReasons || []).find((cr) => cr._id === p.returned.client.reason) ||
        {}
      ).isExchange,
  );
  // must have the 2 options available in return couriers to offer choice
  const hasPickupChoice = returnCouriers.length > 1;

  const handleClose = () => {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push(`/${getUrlParamFromLocale(intl.locale)}/my-orders`);
    }
  };

  const goToNextPage = (fromPage: string, isExchange = false) => {
    switch (fromPage) {
      case 'products':
        if (
          isExchange ||
          (hasOneSimpleRefundMethod &&
            (returnMethod === 'outvio'
              ? hasOneSimpleMethod && !hasPickupChoice
              : hasOneSimpleMethod))
        ) {
          return setPage(3);
        }
        return setPage(2);
      case 'methods':
        return setPage(3);
      case 'instructions':
        return setPage(4);
    }
  };

  const goToPrevPage = (fromPage: string) => {
    switch (fromPage) {
      case 'methods':
        return setPage(1);
      case 'instructions':
        if (
          hasProductWithExchange ||
          (hasOneSimpleRefundMethod &&
            (returnMethod === 'outvio'
              ? hasOneSimpleMethod && !hasPickupChoice
              : hasOneSimpleMethod))
        ) {
          return setPage(1);
        }
        return setPage(2);
    }
  };

  useEffect(() => {
    if (!isLoading && otn) {
      setLoading(true);
      setError(null);

      resetOrderByOTN(otn)
        .then(() =>
          Promise.all([
            requestOrderByOtn(otn),
            getReturnWarehouse(otn),
            getReturnCourierOptions(otn),
            getReturnAllowedMethods(otn),
          ]),
        )
        .then((results) => {
          setOrder(results[0].order);
          setReturnWarehouse(results[1].warehouse);
          setReturnCouriers(results[2].couriers);
          setAvailableReturnMethods(results[3].methods);

          const returnableProducts = results[0].order.products.filter(
            (p: Product) =>
              p.returnable && (isUndefined(p.returned.date) || p.returned.date === null),
          );

          if (returnableProducts.length === 0) {
            throw new Error(intl.formatMessage({ id: 'general.return-error.all-returned' }));
          }

          // If we have 0 return couriers, means we will use the default
          // if we have 2, than end-customer can choose
          // If there is only 1, we send that as selected by design
          if (results[2].couriers.length === 1) {
            const courier = results[2].couriers[0];
            setReturnPickupMode(
              `${courier.courier}.${courier.method}.${
                courier.withPickup ? 'withPickup' : 'noPickup'
              }`,
            );
          }

          setProductsReturning(
            returnableProducts
              .map((p: Product) => {
                return {
                  ...p,
                  stateSelected: false, // Store checkbox state while on step 1
                };
              })
              .sort(sortProductsBySelected), // To make the originally selected product come first initially
          );

          // If there is only 1 return method, we need to initialize it
          const filteredMethods = results[3].methods.filter((m) => m !== 'exchange');
          if (filteredMethods.length === 1) {
            setReturnMethod(filteredMethods[0]);
          }
          // If reimb method does not exists, or its only one option (SAM AS PAYMENT) than we default.
          // Otherwise end-customer needs to select
          if (
            storeData.return.reimbursement &&
            storeData.return.reimbursement === 'CREDIT_IN_STORE'
          ) {
            setRefundMethod(null);
          } else {
            setRefundMethod('SAME_AS_PAYMENT');
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err.message || err.toString());
          setLoading(false);
        });
    }
  }, [otn]);

  const handleUpdateProducts = (products: PartialStateProduct[]) => {
    let hasExchangeReason = false;
    setProductsReturning((prev) =>
      prev.map((stateProd) => {
        const updatedProd = products.find((p) => p._id === stateProd._id);

        if (!updatedProd) {
          return stateProd;
        }

        const customReason = (storeData.return.customReasons || []).find(
          (cr) => cr._id === updatedProd.returned.client.reason,
        );
        if (customReason && customReason.isExchange) {
          hasExchangeReason = true;
        }

        return {
          ...stateProd,
          ...updatedProd,
          returned: {
            ...stateProd.returned,
            client: {
              ...stateProd.returned.client,
              ...updatedProd.returned.client,
            },
          },
        };
      }),
    );

    if (hasExchangeReason) {
      setReturnMethod('exchange');
      goToNextPage('products', true);
    } else {
      goToNextPage('products', false);
    }
  };

  const handleSavePackages = ({
    packages,
    returnCost,
    retHasPickup,
    retLabelMode,
  }: ReturnSavedPackagesObj) => {
    setReturnPackages(packages);
    setReturnCost(returnCost);
    setHasPickup(retHasPickup);
    setLabelMode(retLabelMode);
  };

  const handleSaveAddress = (address: ReturnAddress) => {
    setReturnAddress(address);
  };

  if (isLoading || (!isLoading && order === null && error === null)) {
    return (
      <OVCReturnCartContainer>
        <OVCLoader />
      </OVCReturnCartContainer>
    );
  }

  if (error !== null) {
    return (
      <OVCReturnCartContainer>
        <OVCAlert style={{ marginBottom: '16px' }}>{error}</OVCAlert>

        <div className={classes.errorRestart}>
          <OVCButton
            onClick={() => {
              window.location.assign(`/${getUrlParamFromLocale(intl.locale)}/my-orders`);
            }}
          >
            {intl.formatMessage({ id: 'general.retcart.init-restart' })}
          </OVCButton>
        </div>
      </OVCReturnCartContainer>
    );
  }

  // TODO: unlikely to happen, but should display an error here
  if (returnWarehouse === null || order === null) {
    return null;
  }

  return (
    <OVCReturnCartContainer>
      {page !== 4 && <OVCReturnCartClose onClick={handleClose} />}
      <OVCReturnCartStepper
        page={page}
        skipMethodColumn={hasOneSimpleMethod && hasOneSimpleRefundMethod && !hasPickupChoice}
      />
      <OVCReturnCartInnerContainer>
        {page === 1 && (
          <ReturnCartStep1
            productsReturning={productsReturning}
            onReturnCartUpdateProducts={handleUpdateProducts}
            order={order}
          />
        )}
        {page === 2 && (
          <ReturnCartStepMethods
            returnMethod={returnMethod}
            refundMethod={refundMethod}
            hasPickupChoice={hasPickupChoice}
            returnPickupMode={returnPickupMode}
            setReturnMethod={setReturnMethod}
            setRefundMethod={setRefundMethod}
            setReturnPickupMode={setReturnPickupMode}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            returnCouriers={returnCouriers}
            availableReturnMethods={availableReturnMethods}
          />
        )}
        {page === 3 && (
          <ReturnCartStepInstructions
            returnMethod={returnMethod}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            order={order}
            productsReturning={productsReturning}
            returnPackages={returnPackages}
            onSavePackages={handleSavePackages}
            returnCost={returnCost}
            onSaveAddress={handleSaveAddress}
            hasPickup={hasPickup}
            labelMode={labelMode}
            returnWarehouse={returnWarehouse}
            setExchangeComment={setExchangeComment}
            refundMethod={refundMethod}
            returnPickupMode={returnPickupMode}
          />
        )}
        {page === 4 && (returnMethod === 'outvio' ? returnAddress !== null : true) && (
          <ReturnCartStepConfirmation
            returnMethod={returnMethod}
            refundMethod={refundMethod}
            order={order}
            returnPackages={returnPackages}
            productsReturning={productsReturning}
            returnAddress={returnAddress}
            hasPickup={hasPickup}
            labelMode={labelMode}
            returnWarehouse={returnWarehouse}
            returnExchangeComment={returnExchangeComment}
          />
        )}
      </OVCReturnCartInnerContainer>
    </OVCReturnCartContainer>
  );
};

export default ReturnCartPage;
