import { useParams } from 'react-router-dom';

import TrackingView from '../../components/Common/TrackingView';

interface UrlParams {
  otn: string;
}

const PubTrackingContainer = () => {
  const { otn } = useParams<UrlParams>();

  return <TrackingView otn={otn} />;
};

export default PubTrackingContainer;
