const displayCurrency = (val: unknown, currency?: string) => {
  if (typeof val === 'string') {
    // In case val is already a string, return the string in uppercase
    return val.toUpperCase();
  } else if (typeof val === 'number') {
    // in case val is a number, return the number with appropriate currency
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'EUR',
    }).format(val);
  }
  return '- EUR';
};

export default displayCurrency;
