import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CourierIcon,
  OVCStoreDataContext,
  OVCReturnCartActionRow,
  OVCReturnCartHeading,
  OVCReturnCartMethodsGridContainer,
  OVCReturnCartMethodsGrid,
  OVCReturnCartMethodsBox,
  OVCReturnCartMethodsBoxHelper,
  OVCReturnCartMethodsBoxHelperText,
} from 'outvio-ui';
import { CustomerReturnMethod, CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';

import { Collapse } from '@material-ui/core';

import { ReturnCourier } from './returnCartTypes';

interface IReturnCartStepMethods {
  returnMethod: null | CustomerReturnMethod;
  refundMethod: null | CustomerReimbursement;
  setReturnMethod(value: CustomerReturnMethod): void;
  setRefundMethod(value: CustomerReimbursement): void;
  goToNextPage(value: string): void;
  goToPrevPage(value: string): void;
  hasPickupChoice: boolean;
  returnPickupMode: string | null;
  setReturnPickupMode(value: string): void;
  returnCouriers: ReturnCourier[];
  availableReturnMethods: CustomerReturnMethod[];
}

const ReturnCartStepMethods = ({
  returnMethod,
  setReturnMethod,
  goToNextPage,
  goToPrevPage,
  setRefundMethod,
  refundMethod,
  returnPickupMode,
  setReturnPickupMode,
  hasPickupChoice,
  returnCouriers,
  availableReturnMethods,
}: IReturnCartStepMethods) => {
  const { storeData } = useContext(OVCStoreDataContext);

  return (
    <>
      {(availableReturnMethods.filter((m) => m !== 'exchange').length > 1 || !returnMethod) && (
        <>
          <OVCReturnCartHeading textId="retcart.heading.methods" />

          <OVCReturnCartMethodsGridContainer>
            <OVCReturnCartMethodsGrid>
              {availableReturnMethods.includes('toClientShop') && (
                <OVCReturnCartMethodsBox
                  type="return-toClientShop"
                  isActive={returnMethod === 'toClientShop'}
                  onClick={() => setReturnMethod('toClientShop')}
                />
              )}
              {availableReturnMethods.includes('outvio') && (
                <OVCReturnCartMethodsBox
                  type="return-outvio"
                  isActive={returnMethod === 'outvio'}
                  onClick={() => setReturnMethod('outvio')}
                />
              )}
              {availableReturnMethods.includes('ownCourier') && (
                <OVCReturnCartMethodsBox
                  type="return-ownCourier"
                  isActive={returnMethod === 'ownCourier'}
                  onClick={() => setReturnMethod('ownCourier')}
                />
              )}
            </OVCReturnCartMethodsGrid>
          </OVCReturnCartMethodsGridContainer>
        </>
      )}

      <Collapse in={hasPickupChoice && returnMethod === 'outvio'}>
        <div>
          <OVCReturnCartHeading textId="general.heading.pickup.methods" />
          <OVCReturnCartMethodsGridContainer>
            <OVCReturnCartMethodsGrid>
              {returnCouriers.map((returnCourier) => {
                const courierKey = `${returnCourier.courier}.${returnCourier.method}.${
                  returnCourier.withPickup ? 'withPickup' : 'noPickup'
                }`;
                return (
                  <OVCReturnCartMethodsBox
                    key={courierKey}
                    type="courier"
                    isActive={returnPickupMode === courierKey}
                    onClick={() => setReturnPickupMode(courierKey)}
                    customImage={
                      <CourierIcon courier={returnCourier.courierName} height={60} isCustom />
                    }
                    customTitle={
                      <>
                        {returnCourier.courierName ? (
                          <FormattedMessage
                            id={`general.courier.${returnCourier.courierName.toLowerCase()}`}
                          />
                        ) : (
                          'N/A'
                        )}

                        <br />
                        <span>
                          (
                          <FormattedMessage
                            id={`retcart.title.${returnCourier.withPickup ? 'pickup' : 'dropoff'}`}
                          />
                          )
                        </span>
                      </>
                    }
                    customDesc={
                      returnCourier.withPickup ? (
                        <FormattedMessage id="retcart.method.pickup" />
                      ) : (
                        <FormattedMessage id="retcart.method.dropoff" />
                      )
                    }
                  />
                );
              })}
            </OVCReturnCartMethodsGrid>
          </OVCReturnCartMethodsGridContainer>
        </div>
      </Collapse>

      {storeData.return.reimbursement === 'CREDIT_IN_STORE' && (
        <>
          <OVCReturnCartHeading textId="general.heading.refund.methods" />

          <OVCReturnCartMethodsGridContainer>
            <OVCReturnCartMethodsGrid>
              <OVCReturnCartMethodsBox
                type="refund-SAME_AS_PAYMENT"
                isActive={refundMethod === 'SAME_AS_PAYMENT'}
                onClick={() => setRefundMethod('SAME_AS_PAYMENT')}
              />
              <OVCReturnCartMethodsBox
                type="refund-CREDIT_IN_STORE"
                isActive={refundMethod === 'CREDIT_IN_STORE'}
                onClick={() => setRefundMethod('CREDIT_IN_STORE')}
                customHelper={
                  <OVCReturnCartMethodsBoxHelper>
                    <OVCReturnCartMethodsBoxHelperText type="refund-credit" />
                  </OVCReturnCartMethodsBoxHelper>
                }
              />
            </OVCReturnCartMethodsGrid>
          </OVCReturnCartMethodsGridContainer>
        </>
      )}

      <OVCReturnCartActionRow
        onBackClick={() => goToPrevPage('methods')}
        submitDisabled={
          returnMethod === null ||
          refundMethod === null ||
          (hasPickupChoice && returnMethod === 'outvio' && returnPickupMode === null)
        }
        customSubmitAction={() => goToNextPage('methods')}
      />
    </>
  );
};

export default ReturnCartStepMethods;
