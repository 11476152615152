import { useContext, useMemo, useState } from 'react';
import { OVCStoreDataContext, opacityToSolidColor, OVCButton } from 'outvio-ui';

import { makeStyles, Dialog, Theme, Tab, Tabs } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import PDFViewer from './PDFViewer';

interface StyleProps {
  bgColor: string;
  textColor: string;
  tenPctText: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  dialogPaper: {
    position: 'relative',
    width: 'fit-content',
    padding: '16px',
    paddingTop: '50px',

    backgroundColor: (props) => props.bgColor,
    borderRadius: '5px',
    boxShadow: (props) => `0 0 10px ${props.tenPctText}`,
  },
  closeHolder: {
    position: 'absolute',
    top: '16px',
    right: '16px',

    display: 'flex',

    fontSize: '35px',
    color: (props) => props.textColor,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  ctaButton: {
    marginTop: '50px',
  },
  backdropRoot: {
    backgroundColor: (props) => `${props.textColor}1A`,
  },
  tabRoot: {
    color: (props) => props.textColor,
    fontSize: '16px',
  },
  indicatorRoot: {
    backgroundColor: (props) => props.textColor,
  },
});

interface IPrintModal {
  labels: string[];
  onClose(): void;
}

const PrintModal = ({ labels, onClose }: IPrintModal) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const tenPctText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.1,
        storeData.portalSettings.colors.secondaryBg,
      ),
    [storeData],
  );
  const classes = useStyles({
    bgColor: storeData.portalSettings.colors.secondaryBg,
    textColor: storeData.portalSettings.colors.primaryText,
    tenPctText,
  });

  const [openTab, setOpenTab] = useState(0);
  const [clickedSend, setClicked] = useState(false);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth={false}
      scroll="body"
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
    >
      <div className={classes.closeHolder} onClick={onClose}>
        <CloseIcon color="inherit" fontSize="inherit" />
      </div>
      <Tabs
        value={openTab}
        onChange={(e, value) => setOpenTab(value)}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{ className: classes.indicatorRoot }}
      >
        {labels.map((label, index) => (
          <Tab
            key={`${index}-${label}`}
            value={index}
            label={`PDF ${index + 1}`}
            classes={{ root: classes.tabRoot }}
          />
        ))}
      </Tabs>
      <PDFViewer url={labels[openTab]} />
      {!clickedSend ? (
        <OVCButton
          onClick={() => {
            setClicked(true);
          }}
          style={{ marginTop: '10px' }}
        >
          No PDF? Click to notify us
        </OVCButton>
      ) : (
        <p>Thanks!</p>
      )}
    </Dialog>
  );
};

export default PrintModal;
