import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { OVCStoreDataContext, OVCReturnCartActionRow, OVCReturnCartHeading } from 'outvio-ui';

import { makeStyles, Theme } from '@material-ui/core';

import { ReturnWarehouse } from './returnCartTypes';
import { OrderData } from '../../types/general';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '260px',

    color: (props) => props.textColor,

    '& > p': {
      marginBottom: 0,
    },
  },
});

interface IInstructionsOwnCourier {
  goToPrevPage(value: string): void;
  goToNextPage(value: string): void;
  returnWarehouse: ReturnWarehouse;
  order: OrderData;
}

const InstructionsOwnCourier = ({
  order,
  goToPrevPage,
  goToNextPage,
  returnWarehouse,
}: IInstructionsOwnCourier) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });

  const returnableUntil = moment
    .utc(order.deliverDate)
    .add(storeData.return.days, 'days')
    .format('DD.MM.YYYY');

  return (
    <>
      <OVCReturnCartHeading textId="retcart.inst.ownCourier.heading" />
      <div className={classes.container}>
        <p style={{ fontWeight: 600 }}>
          <FormattedMessage id="retcart.inst.ownCourier.text1" />
        </p>
        <FormattedMessage
          id="retcart.inst.ownCourier.text2"
          values={{ dueDate: returnableUntil }}
          tagName="p"
        />
        <br />
        <FormattedMessage id="retcart.inst.ownCourier.text3" tagName="p" />
        <br />
        <p>{storeData.name}</p>
        <p>{returnWarehouse.address.address}</p>
        <p>
          {returnWarehouse.address.city}, {returnWarehouse.address.postcode}
        </p>
        <FormattedMessage id={`cc.${returnWarehouse.address.countryCode}`} tagName="p" />
        <br />
        <FormattedMessage id="retcart.inst.ownCourier.text4" tagName="p" />
      </div>

      <OVCReturnCartActionRow
        onBackClick={() => goToPrevPage('instructions')}
        customSubmitAction={() => goToNextPage('instructions')}
      />
    </>
  );
};

export default InstructionsOwnCourier;
