import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PDFObject from 'pdfobject';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Loader } from 'outvio-ui';
import print from 'print-js';

import { makeStyles } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles({
  pdfPage: {
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    marginBottom: '8px',
  },
  pdfHeader: {
    position: 'sticky',
    top: 0,
    width: '100%',
    height: '60px',
    zIndey: 1,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    padding: '0 16px',
    color: '#ffffff',
    fontSize: '24px',
    backgroundColor: '#333639',

    '& > *': {
      cursor: 'pointer',
      marginLeft: '16px',
    },
  },
  docHolder: {
    width: '100%',
    padding: '16px',
    backgroundColor: '#535659',
  },
});

interface IPDFViewerProps {
  url: string;
  className?: string;
  width?: number | string;
  height?: number | string;
}

const PDFViewer = ({ url, className = '', width = 500, height = 740 }: IPDFViewerProps) => {
  const classes = useStyles();
  const isMounted = useRef(false);
  const containerRef = useRef(null);

  const [displayMode, setDisplayMode] = useState<'embed' | 'pdfjs'>('embed');
  const [numOfPages, setNumOfPages] = useState<number>(0);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (url && containerRef.current) {
      if (PDFObject.supportsPDFs) {
        PDFObject.embed(url, containerRef.current);
        setDisplayMode('embed');
      } else {
        setDisplayMode('pdfjs');
      }
    }
  }, [url]);

  const renderLoader = useCallback(() => <Loader />, []);

  const handleLoadSuccess = useCallback((p) => {
    setNumOfPages(p.numPages);
  }, []);

  const pages = useMemo(
    () =>
      Array.from(new Array(numOfPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={parseInt(String(width)) - 32}
          className={classes.pdfPage}
        />
      )),
    [numOfPages, width, classes],
  );

  const style = useMemo(
    () => ({
      width: `${width}px`,
      height: `${height}px`,
      ...(displayMode === 'pdfjs'
        ? ({
            position: 'relative',
            overflowX: 'hidden',
            overflowY: 'auto',
          } as React.CSSProperties)
        : ({} as React.CSSProperties)),
    }),
    [width, height, displayMode],
  );

  const handlePrint = useCallback(async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onload = function () {
        if (reader.result && typeof reader.result === 'string') {
          print({
            printable: reader.result.split(',')[1],
            type: 'pdf',
            base64: true,
          });
        }
      };
      reader.readAsDataURL(blob);
    } catch (err) {
      console.error(err);
    }
  }, [url]);

  const handleDownload = useCallback(() => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'file.pdf');
    element.setAttribute('target', '_blank');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, []);

  return (
    <div className={className} style={style} ref={containerRef}>
      {displayMode === 'pdfjs' && (
        <>
          <div className={classes.pdfHeader}>
            <DownloadIcon color="inherit" fontSize="inherit" onClick={handleDownload} />
            <PrintIcon color="inherit" fontSize="inherit" onClick={handlePrint} />
          </div>
          <div className={classes.docHolder}>
            <Document file={url} loading={renderLoader} onLoadSuccess={handleLoadSuccess}>
              {pages}
            </Document>
          </div>
        </>
      )}
    </div>
  );
};

export default PDFViewer;
