import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { OVCAlert, OVCInput, OVCAuthMakeReturnCTA } from 'outvio-ui';

import { requestLoginWithEmail } from '../../../utils/api';
import getUrlParamFromLocale from '../../../utils/getUrlParamFromLocale';

const FormSchema = Yup.object().shape({
  orderNumber: Yup.string().required(),
  clientInfo: Yup.string().required(),
});

const LoginBoxOrder = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState<null | string>(null);

  return (
    <Formik
      validationSchema={FormSchema}
      validateOnChange={false}
      initialValues={{
        orderNumber: '',
        clientInfo: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        requestLoginWithEmail({
          orderIdentifier: values.orderNumber,
          clientIdentifier: values.clientInfo,
          language: location.pathname.split('/')[1],
        })
          .then((res) => {
            history.push(`/${getUrlParamFromLocale(intl.locale)}/return/?token=${res.token}`);
          })
          .catch((err) => {
            setError(err.message || err.toString());
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <OVCInput
            placeholder={intl.formatMessage({ id: 'ovc.auth.order.orderNumber.placeholder' })}
            name="orderNumber"
            onChange={handleChange}
            value={values.orderNumber}
            disabled={isSubmitting}
          />
          <OVCInput
            placeholder={intl.formatMessage({ id: 'ovc.auth.order.clientInfo.placeholder' })}
            name="clientInfo"
            onChange={handleChange}
            value={values.clientInfo}
            disabled={isSubmitting}
          />
          <OVCAuthMakeReturnCTA
            type="submit"
            disabled={isSubmitting}
            showLoader={isSubmitting}
            fullWidth
            style={{ marginTop: '8px' }}
          />
          <OVCAlert isOpen={error !== null}>{error}</OVCAlert>
        </form>
      )}
    </Formik>
  );
};

export default LoginBoxOrder;
