import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import set from 'lodash-es/set';
import get from 'lodash-es/get';
import {
  OVCListingContainer,
  OVCListingContext,
  OVCListingEntry,
  OVCListingEntryCtaRow,
  OVCListingReturnCTA,
  OVCListingTrackCTA,
  OVCListingExternalActionCTA,
  useSingleLoading,
  useIsMounted,
  OVCAlert,
  OVCLoader,
  getProductReturnIndicators,
  OVCStoreDataContext,
} from 'outvio-ui';
import { CustomerListingPage } from 'outvio-ui/lib/types/CustomerGeneral';

import { useMediaQuery, Theme } from '@material-ui/core';

import { requestMyOrders, requestOrders, requestReturns } from '../../utils/api';
import { CustomerStoreData } from 'outvio-ui/lib/types/CustomerStoreData';

const transformReturnEntries = (orders: any[] = []): any[] => {
  const arr: any[] = [];

  orders.forEach((order) => {
    (order?.shipping?.shipments?.return || []).forEach((returnShipment: any) => {
      const products = order.shipping.packages.return.reduce((acc: any[], pack: any) => {
        if (returnShipment.packages.includes(pack._id)) {
          return acc.concat(pack.products);
        }
        return acc;
      }, []);
      arr.push({
        ...order,
        products,
      });
    });
  });

  return arr;
};

const calcCanMakeReturn = (order: any, storeData: CustomerStoreData) => {
  return order.products.some((product: any) => {
    const [, isReturned, isReturnable, isStoreReturnable] = getProductReturnIndicators({
      storeReturnDays: storeData.return.days,
      order,
      product,
    });

    return !isReturned && isReturnable && isStoreReturnable;
  });
};

const OrdersListing = () => {
  const isMounted = useIsMounted();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { storeData } = useContext(OVCStoreDataContext);

  const [isLoading, error, list, setLoading, setError, setList] = useSingleLoading<any[]>(false);
  const [page, setPage] = useState<CustomerListingPage>('all');

  const loadPage = useCallback(() => {
    if (isLoading) {
      return;
    }

    setLoading(true);

    const params = { hostname: window.location.hostname, sortOrder: 'desc', sort: 'date' };
    const promise =
      page === 'all'
        ? requestMyOrders(params)
        : page === 'in-transit'
        ? requestOrders(params)
        : page === 'returns'
        ? requestReturns({ hostname: window.location.hostname })
        : Promise.resolve();

    promise
      .then((res) => {
        if (isMounted.current) {
          if (typeof res.orders !== 'undefined') {
            setList(
              res.orders.map((order: any) =>
                set(order, 'canMakeReturn', calcCanMakeReturn(order, storeData)),
              ),
            );
          } else if (typeof res.returns !== 'undefined') {
            setList(transformReturnEntries(res.returns));
          } else {
            setList([]);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          console.log(err);
          setError(err.message || err.toString());
          setLoading(false);
        }
      });
  }, [isLoading, page]);

  useEffect(() => {
    loadPage();
  }, [page]);

  const onChangePage = useCallback((value) => setPage(value), []);

  const listingContextValue = useMemo(
    () => ({ listingType: page, onChangeListingType: onChangePage, lockdown: isLoading }),
    [page, onChangePage, isLoading],
  );

  return (
    <OVCListingContext.Provider value={listingContextValue}>
      <OVCListingContainer>
        {isLoading && <OVCLoader />}
        {!isLoading && error !== null && <OVCAlert>{error}</OVCAlert>}
        {!isLoading && error === null && (list === null || list.length === 0) && (
          <OVCAlert type="info">
            <FormattedMessage id="ovc.listing.empty-list" />
          </OVCAlert>
        )}
        {!isLoading &&
          error === null &&
          list !== null &&
          list.length > 0 &&
          list.map((item) => (
            <OVCListingEntry
              key={item._id}
              order={item}
              pageType={page}
              ctaRow={
                page === 'all' ? (
                  <OVCListingEntryCtaRow>
                    {item.canMakeReturn && (
                      <OVCListingReturnCTA otn={item.otn} fullWidth={isMobile} />
                    )}
                    <OVCListingExternalActionCTA order={item} fullWidth={isMobile} />
                  </OVCListingEntryCtaRow>
                ) : page === 'in-transit' ? (
                  <OVCListingEntryCtaRow>
                    <OVCListingTrackCTA otn={item.otn} fullWidth={isMobile} />
                  </OVCListingEntryCtaRow>
                ) : (
                  <OVCListingEntryCtaRow>
                    <OVCListingTrackCTA
                      otn={get(item, 'shipping.shipments.return.0.otn', item.otn)}
                      fullWidth={isMobile}
                    />
                  </OVCListingEntryCtaRow>
                )
              }
            />
          ))}
      </OVCListingContainer>
    </OVCListingContext.Provider>
  );
};

export default OrdersListing;
