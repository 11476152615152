export type LocalePage = 'customerOrder' | 'customerLanding' | 'account' | 'general' | 'countries';

//  hr-HR, cs-CZ, el-GR, hu-HU, ga-IE, ro-RO, sk-SK, sl-SI, no-NO
export type SupportedLanguage =
  | 'es-ES'
  | 'ca-ES'
  | 'en-EN'
  | 'et-EE'
  | 'fr-FR'
  | 'it-IT'
  | 'de-DE'
  | 'fi-FI'
  | 'lv-LV'
  | 'lt-LT'
  | 'pt-PT'
  | 'da-DK'
  | 'sv-SE'
  | 'pl-PL'
  | 'nl-NL'
  | 'bg-BG'
  | 'hr-HR'
  | 'cs-CZ'
  | 'el-GR'
  | 'hu-HU'
  | 'ga-IE'
  | 'ro-RO'
  | 'sk-SK'
  | 'sl-SI'
  | 'no-NO';

export enum SupportedLanguages {
  'es-ES',
  'ca-ES',
  'en-EN',
  'et-EE',
  'fr-FR',
  'it-IT',
  'de-DE',
  'fi-FI',
  'lt-LT',
  'lv-LV',
  'pt-PT',
  'pl-PL',
  'sv-SE',
  'da-DK',
  'nl-NL',
  'bg-BG',
  'hr-HR',
  'cs-CZ',
  'el-GR',
  'hu-HU',
  'ga-IE',
  'ro-RO',
  'sk-SK',
  'sl-SI',
  'no-NO',
}

export type SupportedLanguage2LetterList =
  | 'es'
  | 'ca'
  | 'en'
  | 'et'
  | 'fr'
  | 'it'
  | 'de'
  | 'fi'
  | 'lt'
  | 'lv'
  | 'pt'
  | 'sv'
  | 'da'
  | 'pl'
  | 'nl'
  | 'bg'
  | 'hr'
  | 'cs'
  | 'el'
  | 'hu'
  | 'ga'
  | 'ro'
  | 'sk'
  | 'sl'
  | 'no';

export enum SupportedLanguage2Letter {
  'es',
  'ca',
  'en',
  'et',
  'fr',
  'it',
  'de',
  'fi',
  'lt',
  'lv',
  'pt',
  'pl',
  'sv',
  'da',
  'nl',
  'bg',
  'hr',
  'cs',
  'el',
  'hu',
  'ga',
  'ro',
  'sk',
  'sl',
  'no',
}

export interface ReactSelectOption {
  value: string;
  label: string;
}

export type ProductReturnedField = {
  client: {
    reason: string;
    images: string[];
    comment: string;
  };
  store: {
    accepted?: null | boolean;
    reason: string;
    images: string[];
    comment: string;
  };
  date?: null | string;
};

export interface Product {
  _id: string;
  name: string;
  price: number;
  discountPrice: number;
  vat: number;
  quantity: number;
  barcode: string;
  sku: string;
  variant: string;
  weight: number;
  pictureUrl: string;
  originalId: string;
  description: string;
  hsCode: string;
  picked: boolean;
  packaged: boolean;
  returned: ProductReturnedField;
  returnable: boolean;
}

export interface Address {
  _id: string;
  name: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  province: string;
  countryCode: string;
  contactName: string;
  contactPhone: string;
  comments: string;
  dni: string;
}

export interface ListingOrder {
  _id: string;
  id: string;
  originalId: string;
  otn: string;
  dateTime: string;
  currency: string;
  total: number;
  tax: number;
  shipping: {
    price: number;
  };
  products: Product[];
}

export interface ShipmentData {
  delivery: Address;
  pickup: Address;
  courier: string;
  method: string;
  processDate: string;
  estimatedDeliverDate: string;
  trackingNumber: string;
  secondaryCourier?: string;
  secondaryTrackingNumber?: string;
}

export interface PackageData {
  _id: string;
  courier: string;
  method: string;
  otn: string;
  packaging: { height: number; width: number; length: number; name: string };
  products: Product[];
}

export interface OrderData {
  _id: string;
  deliverDate: string;
  products: Product[];
  currency: string;
  otn: string;
  id: string;
  originalId: string;
  dateTime: string;
  total: number;
  tax: number;

  shipping: {
    price: number;
    shipments: {
      shipping: ShipmentData[];
    };
    packages: {
      return: PackageData[];
      shipping: PackageData[];
    };
  };
}
