import PackageJson from '../package.json';
import { checkLocalStorage } from 'utils/storage';
// Checks inspired by Modernizr

const checkIntl = () =>
  new Promise((resolve) => {
    if (window.Intl) {
      return resolve(true);
    }
    // In case we have issues, also load:
    // import 'intl/locale-data/jsonp/en';
    // import 'intl/locale-data/jsonp/es';
    return import('intl')
      .then(() => {
        console.log('  Load polyfill: intl');
        resolve(true);
      })
      .catch(() => resolve(false));
  });

const checkIntersectionObserver = () =>
  new Promise((resolve) => {
    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return import('intersection-observer')
        .then(() => {
          console.log('  Load polyfill: intersection-observer');
          resolve(true);
        })
        .catch(() => resolve(false));
    }

    return resolve(true);
  });

const checkSupportAndLoadPolyfills = async () =>
  new Promise((resolve, reject) => {
    Promise.all([checkIntl(), checkIntersectionObserver(), checkLocalStorage()])
      .then((results) => {
        const [isIntlOK, isIntObserverOK, isLocalStorageOK] = results;

        console.log(`version: ${PackageJson.version}`);
        console.log('[ENV_CHECKS] Checks run successfully');
        console.log('  Intl', isIntlOK);
        console.log('  Intersection Observer', isIntObserverOK);
        console.log('  localStorage', isLocalStorageOK);

        if (!isIntlOK || !isIntObserverOK || !isLocalStorageOK) {
          throw new Error('Missing browser features');
        }

        resolve(true);
      })
      .catch(() => {
        reject(new Error('Environment checks failed.'));
      });
  });

export default checkSupportAndLoadPolyfills;
