import { RequestTrackingInfo, requestTrackingInfo } from 'utils/api';
import getISOLangFromParam from 'utils/getISOLangFromParam';
import { CustomerSupportedLanguage2Letter } from 'outvio-ui/lib/types/CustomerGeneral';
import { fiveMinutesCache } from 'utils/constants';
import { useCacheRequest } from 'utils/useCacheRequest';
import { useMemo } from 'react';

export const useTrackingSteps = ({ otn }: { otn: string }) => {
  const trackingLanguage = getISOLangFromParam(
    (window.location.pathname.split('/')[1] as CustomerSupportedLanguage2Letter) || 'en',
  );
  const { source, error, data, isLoading } = useCacheRequest<RequestTrackingInfo>({
    freshRequest: {
      queryKey: ['useTrackingInfoFresh', otn],
      queryFn: () =>
        requestTrackingInfo({
          otn,
          cache: false,
          trackingLanguage,
        }),
      enabled: Boolean(otn),
      cacheTime: fiveMinutesCache,
      staleTime: fiveMinutesCache,
    },
    cacheRequest: {
      queryKey: ['useTrackingInfoCache', otn],
      queryFn: () =>
        requestTrackingInfo({
          otn,
          cache: true,
          trackingLanguage,
        }),
      enabled: Boolean(otn),
      cacheTime: fiveMinutesCache,
      staleTime: fiveMinutesCache,
    },
  });

  return useMemo(
    () => ({
      source,
      error,
      data,
      isLoading,
    }),
    [source, error, data, isLoading],
  );
};
