import { useState, useEffect, useMemo, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldArray, Formik } from 'formik';
import {
  OVCAlert,
  OVCLoader,
  OVCStoreDataContext,
  OVCReturnCartActionRow,
  OVCReturnCartHeading,
} from 'outvio-ui';
import { CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';
import * as Yup from 'yup';

import { makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { insertReturn, deletePackage } from '../../utils/api';
import ReturnPackage from './ReturnPackage';
import {
  ReturnStep2FormValues,
  ReturnStep2Package,
  ReturnLabelMode,
  StateProduct,
  Package,
  ReturnSavedPackagesObj,
} from './returnCartTypes';
import { Product, OrderData } from '../../types/general';
import { CloseReturnPackageParams, ReturnCourierMethod } from '../../utils/api/apiReturns';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    '& p': {
      color: (props) => props.textColor,
      fontSize: '13px',
    },
  },
  packagesContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  addPack: {
    width: '100%',
    maxWidth: '525px',
    padding: '10px 0',
    color: (props) => props.textColor,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    '& > *': { cursor: 'pointer' },
    '& svg': { marginRight: '8px' },
  },
});

const getLabelMode = (pack: Package): ReturnLabelMode => {
  const courier = pack.courier.toLowerCase();
  const method = (pack.method || '').toLowerCase();

  switch (courier) {
    case 'gls':
    case 'glsnew':
    case 'nacex':
    case 'sending':
      return 'noLabel';
    case 'zeleris':
      if (method === 'zeleris dia siguiente') {
        return 'provided';
      }
      return 'byEmail';
    default:
      return 'provided';
  }
};

function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

const FormSchema = Yup.object().shape({
  packages: Yup.array()
    .of(
      Yup.object().shape({
        length: Yup.number().integer().positive().min(1).required(),
        width: Yup.number().integer().positive().min(1).required(),
        height: Yup.number().integer().positive().min(1).required(),
      }),
    )
    .required()
    .min(1),
});

interface IReturnCartStep2 {
  goToNextPage(): void;
  refundMethod: null | CustomerReimbursement;
  returnPickupMode: null | string;
  productsReturning: StateProduct[];
  goToPrevPage(value: string): void;
  order: OrderData;
  returnPackages: Package[];
  onSavePackages(value: ReturnSavedPackagesObj): void;
}

const ReturnCartStep2 = ({
  goToPrevPage,
  order,
  productsReturning,
  returnPackages,
  refundMethod,
  onSavePackages,
  goToNextPage,
  returnPickupMode,
}: IReturnCartStep2) => {
  const intl = useIntl();

  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [defaultPackages, setDefaultPackages] = useState<ReturnStep2Package[]>([]);
  const [skipStepError, setSkipStepError] = useState<null | string>(null);

  useEffect(() => {
    const defPackages = order.shipping.packages.shipping.reduce<ReturnStep2Package[]>(
      (acc, pack) => {
        // Add every shipped packaging, that has a product that has been selected for returning.
        const returningProducts = productsReturning
          .filter((p) => p.stateSelected)
          .map((p) => p._id);
        const productIndex = pack.products.findIndex((prod: Product) =>
          returningProducts.includes(prod._id),
        );
        const containsProduct = productIndex > -1;
        if (containsProduct) {
          return acc.concat([
            {
              length: pack.packaging.length,
              width: pack.packaging.width,
              height: pack.packaging.height,
              defaultSuggested: true,
            },
          ]);
        }
        return acc;
      },
      [],
    );

    setDefaultPackages(defPackages);

    if (storeData.return.customerCanDefineReturnPackaging) {
      setLoading(false);
    } else {
      // Save default packaging and move on
      const valuesPackagesMock =
        returnPackages.length > 0
          ? returnPackages.map((p) => ({
              _id: p._id,
              length: p.packaging.length,
              width: p.packaging.width,
              height: p.packaging.height,
              defaultSuggested: defPackages.some(
                (dp) =>
                  dp.length === p.packaging.length &&
                  dp.width === p.packaging.width &&
                  dp.height === p.packaging.height,
              ),
            }))
          : defPackages;
      const returningPackIds = valuesPackagesMock.map((p) => p._id);
      const retProducts: StateProduct[] = productsReturning.filter((p) => p.stateSelected);
      const productsInOnePackage = Math.floor(retProducts.length / valuesPackagesMock.length);
      const productsRemainder =
        retProducts.length - productsInOnePackage * valuesPackagesMock.length;

      const tempProdHolder: StateProduct[] = retProducts.map((p) => ({ ...p }));

      const toSaveData: CloseReturnPackageParams = {
        order: order._id,
        pickupAddress: order.shipping.shipments.shipping[0].delivery._id,
        reimbursement: refundMethod || 'SAME_AS_PAYMENT',
        withPickup:
          typeof returnPickupMode === 'string'
            ? returnPickupMode.split('.')[2] === 'withPickup'
            : null,
        method: ReturnCourierMethod.COURIER,
        preparedPackages: valuesPackagesMock.map((pack) => ({
          packaging: {
            length: parseInt(String(pack.length)),
            width: parseInt(String(pack.width)),
            height: parseInt(String(pack.height)),
          },
          products: new Array(productsInOnePackage)
            .fill(1)
            .map(() => {
              const p = tempProdHolder.splice(0, 1);
              return p[0];
            })
            .map((p) => ({
              _id: p._id,
              reason: p.returned.client.reason || 'NOT_DEFINED',
              images: p.returned.client.images,
              comment: p.returned.client.comment || '',
            })),
        })),
      };

      // fill in remainder products
      if (productsRemainder > 0) {
        toSaveData.preparedPackages[0].products = toSaveData.preparedPackages[0].products.concat(
          tempProdHolder.splice(0).map((t) => ({
            _id: t._id,
            reason: t.returned.client.reason || 'NOT_DEFINED',
            images: t.returned.client.images,
            comment: t.returned.client.comment || '',
          })),
        );
      }

      // Delete all created packages
      const existingPackIds = returningPackIds.filter(isDefined);

      const cleanupPromises =
        existingPackIds.length > 0
          ? Promise.all(existingPackIds.map((id) => deletePackage(id)))
          : Promise.resolve(true);

      cleanupPromises
        .then(() => {
          return insertReturn(toSaveData).then((res) => {
            onSavePackages({
              packages: res.return,
              returnCost: res.clientReturnCost,
              retHasPickup: res.withPickup,
              retLabelMode: getLabelMode(res.return[0]),
            });
            goToNextPage();
          });
        })
        .catch((err) => {
          console.log(err);
          setSkipStepError(err.message || err.toString());
          setLoading(false);
        });
    }
  }, []);

  const INITIAL_VALUES: ReturnStep2FormValues = useMemo(
    () => ({
      packages:
        returnPackages.length > 0
          ? returnPackages.map((p) => ({
              _id: p._id,
              length: p.packaging.length,
              width: p.packaging.width,
              height: p.packaging.height,
              defaultSuggested: defaultPackages.some(
                (dp) =>
                  dp.length === p.packaging.length &&
                  dp.width === p.packaging.width &&
                  dp.height === p.packaging.height,
              ),
            }))
          : defaultPackages,
    }),
    [returnPackages, defaultPackages],
  );

  if (isLoading) {
    return <OVCLoader />;
  }

  if (skipStepError !== null) {
    return <OVCAlert>{skipStepError}</OVCAlert>;
  }

  return (
    <Formik
      validationSchema={FormSchema}
      initialValues={INITIAL_VALUES}
      onSubmit={async (values, { setSubmitting }) => {
        const returningPackIds = values.packages.map((p) => p._id);
        const retProducts = productsReturning.filter((p) => p.stateSelected);
        const productsInOnePackage = Math.floor(retProducts.length / values.packages.length);
        const productsRemainder =
          retProducts.length - productsInOnePackage * values.packages.length;

        const tempProdHolder = retProducts.map((p) => ({ ...p }));

        const toSaveData: CloseReturnPackageParams = {
          order: order._id,
          pickupAddress: order.shipping.shipments.shipping[0].delivery._id,
          reimbursement: refundMethod || 'SAME_AS_PAYMENT',
          withPickup:
            typeof returnPickupMode === 'string'
              ? returnPickupMode.split('.')[2] === 'withPickup'
              : null,
          method: ReturnCourierMethod.COURIER,
          preparedPackages: values.packages.map((pack) => ({
            packaging: {
              length: parseInt(String(pack.length)),
              width: parseInt(String(pack.width)),
              height: parseInt(String(pack.height)),
            },
            products: new Array(productsInOnePackage)
              .fill(1)
              .map(() => {
                const p = tempProdHolder.splice(0, 1);
                return p[0];
              })
              .map((p) => ({
                _id: p._id,
                reason: p.returned.client.reason || 'NOT_DEFINED',
                images: p.returned.client.images,
                comment: p.returned.client.comment || '',
              })),
          })),
        };

        // fill in remainder products
        if (productsRemainder > 0) {
          toSaveData.preparedPackages[0].products = toSaveData.preparedPackages[0].products.concat(
            tempProdHolder.splice(0).map((t) => ({
              _id: t._id,
              reason: t.returned.client.reason || 'NOT_DEFINED',
              images: t.returned.client.images,
              comment: t.returned.client.comment || '',
            })),
          );
        }

        // Delete all created packages
        const existingPackIds = returningPackIds.filter(isDefined);

        const cleanupPromises =
          existingPackIds.length > 0
            ? Promise.all(existingPackIds.map((id) => deletePackage(id)))
            : Promise.resolve(true);

        try {
          await cleanupPromises;

          const res = await insertReturn(toSaveData);

          onSavePackages({
            packages: res.return,
            returnCost: res.clientReturnCost,
            retHasPickup: res.withPickup,
            retLabelMode: getLabelMode(res.return[0]),
          });
          goToNextPage();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          setError(err.message || err.toString());
        }
      }}
    >
      {({ values, setFieldValue, errors, isSubmitting }) => (
        <>
          <form className={classes.container}>
            <FormattedMessage
              id="retcart.packages.text"
              values={{ lineBreak: <br /> }}
              tagName="p"
            />
            <OVCReturnCartHeading textId="retcart.step2.heading" />
            <FieldArray
              name="packages"
              render={(arrayHelpers) => (
                <div className={classes.packagesContainer}>
                  {values.packages.map((pack, i) => (
                    <ReturnPackage
                      key={i}
                      index={i}
                      pack={pack}
                      handleRemove={() => arrayHelpers.remove(i)}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      disabled={isSubmitting}
                    />
                  ))}
                  <div className={classes.addPack}>
                    <AddIcon
                      color="inherit"
                      onClick={() => {
                        if (!isSubmitting) {
                          arrayHelpers.push({
                            length: '0',
                            width: '0',
                            height: '0',
                            defaultSuggested: false,
                          });
                        }
                      }}
                    />
                    <span
                      onClick={() => {
                        if (!isSubmitting) {
                          arrayHelpers.push({
                            length: '0',
                            width: '0',
                            height: '0',
                            defaultSuggested: false,
                          });
                        }
                      }}
                    >
                      {intl.formatMessage({ id: 'retcart.pack.add' })}
                    </span>
                  </div>
                </div>
              )}
            />

            <OVCAlert isOpen={error !== null}>{error}</OVCAlert>

            <OVCReturnCartActionRow onBackClick={() => goToPrevPage('instructions')} />
          </form>
        </>
      )}
    </Formik>
  );
};

export default ReturnCartStep2;
