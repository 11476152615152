import { useMemo, useState, useContext, useCallback } from 'react';
import {
  OVCStoreDataContext,
  opacityToSolidColor,
  OVCButton,
  OVCLoader,
  OVCAlert,
  OVCInput,
  OVCSelect,
  OVCTextarea,
} from 'outvio-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Address } from 'outvio-ui/lib/types/Address';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash-es/get';

import { makeStyles, Dialog, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { deleteUserAddress } from '../../utils/api';
import { refreshUser } from '../../slices/user';
import { RootState } from '../../reducers';
import { ReturnAddress } from './returnCartTypes';
import { ReactSelectOption } from '../../types/general';
import ReturnCartAddressBox from './ReturnCartAddressBox';
import { COUNTRY_LIST } from '../../constants';

interface StyleProps {
  bgColor: string;
  primaryBg: string;
  textColor: string;
  tenPctText: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  dialogPaper: {
    position: 'relative',
    width: '835px',
    padding: '70px',
    paddingBottom: '30px',

    backgroundColor: (props) => props.bgColor,
    borderRadius: '5px',
    boxShadow: (props) => `0 0 10px ${props.tenPctText}`,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > h1': {
      width: '100%',
      margin: 0,
      marginBottom: '20px',
      color: (props) => props.textColor,

      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
      textTransform: 'uppercase',
      textAlign: 'left',

      borderBottom: (props) => `3px solid ${props.textColor}`,
    },
  },
  closeHolder: {
    position: 'absolute',
    top: '25px',
    right: '25px',

    display: 'flex',

    fontSize: '40px',
    color: (props) => props.textColor,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  ctaButton: {
    marginTop: '50px',
  },
  backdropRoot: {
    backgroundColor: (props) => `${props.textColor}1A`,
  },
  addressGrid: {
    width: '100%',
    marginBottom: '15px',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '20px',
    gridRowGap: '15px',
  },
  formGrid: {
    width: '100%',
    marginBottom: '15px',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '20px',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',

      '& > label': {
        marginTop: '5px',
        marginBottom: '2px',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 600,
        color: (props) => props.textColor,

        '&:first-of-type': {
          marginTop: 0,
        },
      },
    },
  },
});

const sortAddresses = (a: any, b: any) => {
  if (a.address.name < b.address.name) {
    return -1;
  }
  if (a.address.name > b.address.name) {
    return 1;
  }
  return 0;
};

const sortCountries = (a: ReactSelectOption, b: ReactSelectOption) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required(),
  address: Yup.string().required(),
  postcode: Yup.string().required(),
  country: Yup.string(),
  countryCode: Yup.string().required(),
  city: Yup.string().required(),
  province: Yup.string(),
  contactName: Yup.string().required(),
  contactPhone: Yup.string().required(),
  comments: Yup.string(),
});

interface IReturnCartAddressModal {
  handleClose(): void;
  initialAddress: Address;
  onChangeAddress(value: ReturnAddress): void;
}

const ReturnCartAddressModal = ({
  handleClose,
  initialAddress,
  onChangeAddress,
}: IReturnCartAddressModal) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { storeData } = useContext(OVCStoreDataContext);

  const tenPctText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.1,
        storeData.portalSettings.colors.secondaryBg,
      ),
    [storeData],
  );

  const classes = useStyles({
    bgColor: storeData.portalSettings.colors.secondaryBg,
    primaryBg: storeData.portalSettings.colors.primaryBg,
    textColor: storeData.portalSettings.colors.primaryText,
    tenPctText,
  });

  const userData = useSelector((state: RootState) => state.user.data);
  const userAddresses = useMemo(() => [...userData.addresses].sort(sortAddresses), [userData]);

  const [openAddress, setOpenAddress] = useState<Address>({ ...initialAddress });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const handleSelectAddress = useCallback(
    (addressId) => {
      const newAddress = userAddresses.find((a) => a.address._id === addressId);
      if (newAddress) {
        setOpenAddress({ ...newAddress.address });
      }
    },
    [userAddresses],
  );

  const handleRequestDelete = useCallback(
    (addressId) => {
      setLoading(true);
      const underDeleteAddress = userAddresses.find((addr) => addr.address._id === addressId);

      deleteUserAddress(userData?._id, underDeleteAddress?._id)
        .then(() => dispatch(refreshUser()))
        .then(() => setLoading(false))
        .catch((err) => {
          setError(err.message || err.toString());
          setLoading(false);
        });
    },
    [userAddresses, userData, dispatch],
  );

  const countries = useMemo(
    () =>
      COUNTRY_LIST.map((cc) => ({
        value: cc,
        label: intl.formatMessage({ id: `cc.${cc}` }),
      })).sort(sortCountries),
    [intl],
  );

  return (
    <Dialog
      open
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={false}
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{
        classes: {
          root: classes.backdropRoot,
        },
      }}
    >
      {!isLoading && (
        <div className={classes.closeHolder} onClick={handleClose}>
          <CloseIcon color="inherit" fontSize="inherit" />
        </div>
      )}

      <FormattedMessage id="ovc.return-cart.address.heading" tagName="h1" />

      <div className={classes.addressGrid}>
        {userAddresses.map((userAddress) => (
          <ReturnCartAddressBox
            key={userAddress.address._id}
            address={userAddress.address}
            onSelect={handleSelectAddress}
            onRequestDelete={handleRequestDelete}
            isActive={userAddress.address._id === openAddress._id}
            disableDelete={isLoading && userAddresses.length <= 1}
          />
        ))}
      </div>

      <Formik
        enableReinitialize
        validationSchema={FormSchema}
        initialValues={{
          name: get(openAddress, 'name', '') || '',
          address: get(openAddress, 'address', '') || '',
          postcode: get(openAddress, 'postcode', '') || '',
          countryCode:
            typeof get(openAddress, 'countryCode') === 'string' &&
            get(openAddress, 'countryCode', '').length === 2
              ? get(openAddress, 'countryCode')
              : 'ES',
          city: get(openAddress, 'city', '') || '',
          province: get(openAddress, 'province', '') || '',
          contactName: get(openAddress, 'contactName', '') || '',
          contactPhone: get(openAddress, 'contactPhone', '') || '',
          comments: get(openAddress, 'comments', '') || '',
        }}
        onSubmit={(values) => {
          onChangeAddress({
            ...values,
            country: countries.find((opt) => opt.value === values.countryCode)?.label,
          });
          handleClose();
        }}
      >
        {({ submitForm, isSubmitting, handleChange, values }) => (
          <>
            <div className={classes.formGrid}>
              <div>
                <FormattedMessage tagName="label" id="account.address.name" />
                <OVCInput
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />

                <FormattedMessage tagName="label" id="account.address.contactName" />
                <OVCInput
                  name="contactName"
                  onChange={handleChange}
                  value={values.contactName}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />

                <FormattedMessage tagName="label" id="account.address.address" />
                <OVCInput
                  name="address"
                  onChange={handleChange}
                  value={values.address}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />

                <FormattedMessage tagName="label" id="account.address.postcode" />
                <OVCInput
                  name="postcode"
                  onChange={handleChange}
                  value={values.postcode}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />

                <FormattedMessage tagName="label" id="account.address.city" />
                <OVCInput
                  name="city"
                  onChange={handleChange}
                  value={values.city}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />
              </div>
              <div>
                <FormattedMessage tagName="label" id="account.address.province" />
                <OVCInput
                  name="province"
                  onChange={handleChange}
                  value={values.province}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />

                <FormattedMessage tagName="label" id="account.address.country" />
                <OVCSelect
                  name="countryCode"
                  options={countries}
                  value={values.countryCode}
                  onChange={handleChange}
                  disabled={isSubmitting || isLoading}
                />

                <FormattedMessage tagName="label" id="account.address.contactPhone" />
                <OVCInput
                  name="contactPhone"
                  onChange={handleChange}
                  value={values.contactPhone}
                  disabled={isSubmitting || isLoading}
                  useSecondaryBg
                />

                <FormattedMessage tagName="label" id="orders.returns.pickup.form.comments" />
                <OVCTextarea
                  name="comments"
                  onChange={handleChange}
                  value={values.comments}
                  disabled={isSubmitting || isLoading}
                />
              </div>
            </div>

            {isLoading && <OVCLoader />}
            <OVCAlert isOpen={error !== null}>{error}</OVCAlert>

            <OVCButton
              className={classes.ctaButton}
              disabled={isLoading || isSubmitting}
              onClick={submitForm}
              showLoader={isSubmitting}
            >
              <FormattedMessage id="general.confirm" />
            </OVCButton>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ReturnCartAddressModal;
