import { Switch, Route } from 'react-router-dom';

import { OVCLangSwitcherContext, OVCI18NProvider } from 'outvio-ui';

import { AVAILABLE_LANGUAGES } from './constants';
import Layout from './components/Common/Layout';

import PubTracking from './pages/PubTracking';

import SpecificTracking from './pages/SpecificTracking/SpecificTrackingPage';

import { RedirectIndexRoute, RedirectPubTrackingRoute } from './AppRoutes';
import { useLanguageRouter } from './hooks/useLanguageRouter';

const LanguageRouter = ({ isTracking }: { isTracking: boolean }) => {
  const { appLang, handleLangChange, match } = useLanguageRouter();
  return (
    <OVCLangSwitcherContext.Provider value={{ appLang, changeLangTo: handleLangChange }}>
      <OVCI18NProvider>
        <Layout isTracking={isTracking}>
          <Switch>
            <Route exact path={`${match.url}/`} component={SpecificTracking} />
            <Route exact path={`${match.url}/id/:otn`} component={PubTracking} />
            <Route exact path={`${match.url}/tracking`} component={SpecificTracking} />
          </Switch>
        </Layout>
      </OVCI18NProvider>
    </OVCLangSwitcherContext.Provider>
  );
};

const AppTracking = ({ isTracking }: { isTracking: boolean }) => {
  return (
    <>
      <Switch>
        {RedirectPubTrackingRoute}
        <Route path={`/:urlLang(${AVAILABLE_LANGUAGES.join('|')})`}>
          <LanguageRouter isTracking={isTracking} />
        </Route>
        {RedirectIndexRoute}
      </Switch>
    </>
  );
};

export default AppTracking;
