import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from 'outvio-ui/lib/types/Address';

import { requestUser } from '../utils/api';
import { AppThunk } from '../store';

export interface UserData {
  _id: string;
  email: string;
  addresses: {
    _id: string;
    default: boolean;
    address: Address;
  }[];
  firstname: string;
  lastname: string;
}

interface UserState {
  data: UserData;
}

const userInitialState: UserState = {
  data: {
    _id: '',
    email: '',
    firstname: '',
    lastname: '',
    addresses: [],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    userLoaded(state, { payload }: PayloadAction<UserData>) {
      state.data = payload;
    },
  },
});

export const { userLoaded } = userSlice.actions;

export const refreshUser = (): AppThunk => async (dispatch) => {
  const userRes = await requestUser();

  dispatch(userLoaded(userRes.user));
};

export default userSlice.reducer;
