import { useState, useEffect, useRef, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  OVCLoader,
  OVCAlert,
  OVCStoreDataContext,
  OVCButton,
  OVCReturnCartHeading,
} from 'outvio-ui';
import { CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';
import { useHistory } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core';

import {
  returnToClientShop,
  deletePackage,
  handleExchangeFinish,
  CloseReturnPackageParams,
  ReturnCourierMethod,
} from '../../utils/api';
import getUrlParamFromLocale from '../../utils/getUrlParamFromLocale';

import { StateProduct } from './returnCartTypes';
import { OrderData } from '../../types/general';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '260px',
    color: (props) => props.textColor,

    '& > p': {
      marginBottom: 0,
    },
  },
  interContainer: {
    width: '100%',
    minHeight: '300px',
    padding: '24px 50px',

    textAlign: 'center',
    color: (props) => props.textColor,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& h2': {
      fontSize: '18px',
      marginBottom: '16px',
    },
  },
  action: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface IConfirmationExchange {
  refundMethod: null | CustomerReimbursement;
  returnExchangeComment: string;
  productsReturning: StateProduct[];
  order: OrderData;
}

const ConfirmationExchange = ({
  productsReturning,
  refundMethod,
  returnExchangeComment,
  order,
}: IConfirmationExchange) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });
  const history = useHistory();
  const intl = useIntl();

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const processFinished = useRef(false);

  useEffect(() => {
    const newReturnProducts = productsReturning
      .filter((p) => p.stateSelected)
      .map((p) => ({
        _id: p._id,
        reason: p.returned.client.reason || 'NOT_DEFINED',
        images: p.returned.client.images,
        comment: p.returned.client.comment || '',
      }));

    const originalShippingPackage = order.shipping.packages.shipping.find((pack) =>
      pack.products.find((p) => p._id === newReturnProducts[0]._id),
    );

    const sendData: CloseReturnPackageParams = {
      method: ReturnCourierMethod.EXCHANGE,
      reimbursement: refundMethod || 'SAME_AS_PAYMENT',
      order: order._id,
      pickupAddress: order.shipping.shipments.shipping[0].delivery._id,
      withPickup: null,
      returnComment: returnExchangeComment,
      preparedPackages: [
        {
          products: newReturnProducts,
          ...(originalShippingPackage?.package
            ? { package: originalShippingPackage?.package || 'NOT_DEFINED' }
            : {
                packaging: {
                  length: originalShippingPackage?.packaging.length || 1,
                  width: originalShippingPackage?.packaging.width || 1,
                  height: originalShippingPackage?.packaging.height || 1,
                },
              }),
        },
      ],
    };

    const retPacksWithProdsThisReturn = order.shipping.packages.return.filter((pack) =>
      pack.products.some((prod) => {
        if (typeof prod === 'string') {
          return Boolean(newReturnProducts.find((p) => p._id === prod));
        } else {
          return Boolean(newReturnProducts.find((p) => p._id === prod._id));
        }
      }),
    );

    const cleanupPromise =
      retPacksWithProdsThisReturn.length > 0
        ? Promise.all(retPacksWithProdsThisReturn.map((pack) => deletePackage(pack._id)))
        : Promise.resolve(true);

    cleanupPromise
      .then(() => returnToClientShop(sendData))
      .then(() => handleExchangeFinish({ orderId: order._id, message: returnExchangeComment }))
      .then(() => {
        processFinished.current = true;
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message || err.toString());
        setLoading(false);
      });
  }, []);

  const handleFinish = () => {
    history.push(`/${getUrlParamFromLocale(intl.locale)}/my-orders`);
  };

  if (isLoading) {
    return (
      <div className={classes.interContainer}>
        <OVCReturnCartHeading textId="retcart.print.loading.heading" />
        <OVCLoader />
        <p style={{ marginTop: '16px' }}>
          {intl.formatMessage({ id: 'retcart.print.loading.note' })}
        </p>
      </div>
    );
  }

  if (!isLoading && error !== null) {
    return (
      <div className={classes.interContainer}>
        <OVCReturnCartHeading textId="retcart.print.loading.heading" />
        <OVCAlert>{error}</OVCAlert>

        <OVCButton
          onClick={() => {
            window.location.reload();
          }}
        >
          {intl.formatMessage({ id: 'general.retcart.restart.btn' })}
        </OVCButton>
      </div>
    );
  }
  return (
    <>
      <OVCReturnCartHeading textId="retcart.confirm.exchange.heading" />
      <div className={classes.container}>
        <p>
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage id="retcart.confirm.exchange.text1" />
          </span>
          <br />
          <FormattedMessage id="retcart.confirm.exchange.text2" />
        </p>
        <br />
        <FormattedMessage id="retcart.confirm.exchange.text3" tagName="p" />
        <br />
        <br />
        <p>
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage id="retcart.confirm.exchange.text4" />
          </span>
        </p>
      </div>
      <div className={classes.action}>
        <OVCButton onClick={handleFinish}>{intl.formatMessage({ id: 'general.done' })}</OVCButton>
      </div>
    </>
  );
};

export default ConfirmationExchange;
