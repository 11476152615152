import { useContext } from 'react';
import get from 'lodash-es/get';
import { FieldArray, Formik } from 'formik';
import {
  OVCStoreDataContext,
  OVCReturnCartActionRow,
  OVCReturnCartHeading,
  OVCReturnCartProductsContainer,
  OVCReturnCartProduct,
  DEFAULT_RETURN_REASONS,
} from 'outvio-ui';

import { StateProduct, PartialStateProduct, ReturnStep1FormValues } from './returnCartTypes';
import { OrderData } from '../../types/general';
import { insertImage } from '../../utils/api';

interface IReturnCartStep1 {
  onReturnCartUpdateProducts(products: PartialStateProduct[]): void;
  order: OrderData;
  productsReturning: StateProduct[];
}

const ReturnCartStep1 = ({
  order,
  productsReturning,
  onReturnCartUpdateProducts,
}: IReturnCartStep1) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const INITIAL_VALUES: ReturnStep1FormValues = {
    products: productsReturning.map((p) => ({
      _id: p._id,
      isSelected: p.stateSelected,
      reason: get(p, 'returned.client.reason'),
      comment: get(p, 'returned.client.comment', ''),
      images: get(p, 'returned.client.images', []),
      name: get(p, 'name', ''),
      variant: get(p, 'variant', ''),
      sku: get(p, 'sku', ''),
      price: get(p, 'price', 0),
      discountPrice: get(p, 'discountPrice'),
      quantity: get(p, 'quantity'),
      pictureUrl: get(p, 'pictureUrl', ''),
    })),
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors: { products: any[] } = { products: [] };
        let hadError = false;

        values.products.forEach(() => {
          errors.products.push({});
        });

        values.products.forEach((prod, i) => {
          if (prod.isSelected && (prod.reason === '' || typeof prod.reason === 'undefined')) {
            errors.products[i].reason = 'required';
            hadError = true;
          }
          if (prod.comment.length > 150) {
            errors.products[i].comment = 'long';
            hadError = true;
          }
          // if custom prod, it can require at least 1 image
          if (
            prod.isSelected &&
            typeof prod.reason !== 'undefined' &&
            prod.reason !== '' &&
            !DEFAULT_RETURN_REASONS.includes(prod.reason)
          ) {
            const customReason = storeData.return.customReasons.find(
              (cr) => cr._id === prod.reason,
            );
            if (customReason && customReason.forcedImage && prod.images.length === 0) {
              errors.products[i].images = 'required';
              hadError = true;
            }
          }
        });

        if (!hadError) {
          return {};
        }
        return errors;
      }}
      onSubmit={(values) => {
        onReturnCartUpdateProducts(
          values.products.map((p) => ({
            _id: p._id,
            stateSelected: p.isSelected,
            returned: {
              client: {
                reason: p.reason,
                comment: p.comment,
                images: p.images,
              },
            },
          })),
        );
      }}
    >
      {({ values }) => (
        <>
          <OVCReturnCartHeading textId="general.products-returned" />
          <OVCReturnCartProductsContainer>
            <FieldArray
              name="products"
              render={() =>
                values.products.map((product, index) => (
                  <OVCReturnCartProduct
                    key={product._id}
                    fieldName={`products[${index}]`}
                    product={product}
                    currency={order.currency}
                    insertImage={insertImage}
                  />
                ))
              }
            />
          </OVCReturnCartProductsContainer>
          <OVCReturnCartActionRow noPrev />
        </>
      )}
    </Formik>
  );
};

export default ReturnCartStep1;
