import { CustomerSupportedLanguage2Letter } from 'outvio-ui/lib/types/CustomerGeneral';

const getISOLangFromParam = (locale: CustomerSupportedLanguage2Letter): string => {
  switch (locale) {
    case 'en':
      return 'en-EN';
    case 'es':
      return 'es-ES';
    case 'ca':
      return 'ca-ES';
    case 'et':
      return 'et-EE';
    case 'fr':
      return 'fr-FR';
    case 'it':
      return 'it-IT';
    case 'de':
      return 'de-DE';
    case 'fi':
      return 'fi-FI';
    case 'lt':
      return 'lt-LT';
    case 'lv':
      return 'lv-LV';
    case 'pt':
      return 'pt-PT';
    case 'pl':
      return 'pl-PL';
    default:
      return 'en';
  }
};

export default getISOLangFromParam;
