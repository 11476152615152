import { combineReducers } from '@reduxjs/toolkit';

import app from '../slices/app';
import user from '../slices/user';

const rootReducer = combineReducers({
  app,
  user,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
