import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OVCLoader } from 'outvio-ui';
import OVLocalCache from 'utils/storage';

interface UrlParams {
  token?: string;
}

const ImpersonateLogin = () => {
  const { token } = useParams<UrlParams>();

  useEffect(() => {
    if (token) {
      OVLocalCache.set('token', token).then(() => {
        window.location.assign('/en/my-orders');
      });
    }
  }, []);

  return <OVCLoader />;
};

export default ImpersonateLogin;
