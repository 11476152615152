import request from './request';

export const insertImage = (data: File): Promise<{ file: any }> => {
  const formData = new FormData();
  formData.append('image', data);

  return request(`${OUTVIO_API_URL}/upload`, {
    method: 'POST',
    data: formData,
    secure: true,
    upload: true,
  });
};

export const getSignedUrl = (url: string): Promise<{ url: string }> => {
  return request(`${OUTVIO_API_URL}/upload/create-signed-url`, {
    method: 'POST',
    data: { key: url },
    secure: true,
  });
};
