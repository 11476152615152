import request from './request';

import { Address } from '../../types/general';
import { TrackingStep } from 'outvio-ui/lib/types/Tracking';
import { CustomerStoreData } from 'outvio-ui/lib/types/CustomerStoreData';
import { SourceDestinationAddress, SourceStep, SourceTrackingData } from 'tracking-fe';

export interface ResponseOrderTrackingData {
  // if order is not yet shipped, these might be missing
  trackingNumber?: string;
  processDate?: string;
  returnProcessDate?: string;
  estimatedDeliverDate?: string;
  secondaryTrackingNumber?: string;
  secondaryCourier?: string;
  // always present
  otn: string;
  originAddress: Address;
  origin: string;
  destination: string;
  destinationAddress: Address;
  courier: string;
  method: string;
  steps: TrackingStep[];
}

export const requestTrackingData = ({
  otn,
  trackingLanguage,
}: {
  otn: string;
  trackingLanguage: string;
}): Promise<SourceTrackingData> =>
  request(`${OUTVIO_API_URL}/customer/order/${otn}/general`, {
    method: 'GET',
    params: {
      trackingLanguage,
    },
  }).then((res) => ({ ...res.tracking, rating: res.rating }));

interface ResponseTrackingDataMultiOrder {
  otn: string;
  products: any;
  discountApplied?: number;
  currency: string;
  tax: number;
  total: number;
  shipping: { price: number };
}
export const requestTrackingDataMultiOrder = ({
  otn,
  trackingLanguage,
}: {
  otn: string;
  trackingLanguage: string;
}): Promise<ResponseTrackingDataMultiOrder[]> =>
  request(`${OUTVIO_API_URL}/customer/order/${otn}/split-orders`, {
    method: 'GET',
    params: {
      trackingLanguage,
    },
  }).then((res) => res.data);

export interface RequestTrackingInfo {
  tracking: { steps: SourceStep[]; packages?: string[] };
}

export const requestTrackingInfo = ({
  otn,
  trackingLanguage,
  cache = false,
}: {
  otn: string;
  trackingLanguage: string;
  cache?: boolean;
}): Promise<RequestTrackingInfo> =>
  request(`${OUTVIO_API_URL}/customer/order/${otn}/track`, {
    method: 'GET',
    params: { trackingLanguage, cache },
  });

export const requestStoreInfo = (params: {
  otn?: string;
}): Promise<{ data: CustomerStoreData & { customDomains: any } }> =>
  request(`${OUTVIO_API_URL}/customer/store`, { method: 'GET', params });

export const getOTNFromIdentifier = (params: {
  orderIdentifier: string;
  postcode: string;
}): Promise<{ otn: string }> =>
  request(`${OUTVIO_API_URL}/customer/identify-otn`, { method: 'GET', params });

export const requestSendRating = ({ token, data }: { token: string; data: { rating: number } }) =>
  request(`${OUTVIO_API_URL}/customer/order/${token}/rating`, { method: 'POST', data });

export const requestChangeDeliveryAddress = ({
  token,
  data,
}: {
  token: string;
  data: Partial<SourceDestinationAddress>;
}) => request(`${OUTVIO_API_URL}/customer/order/${token}/address`, { method: 'POST', data });
