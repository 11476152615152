import { useState, useContext, useMemo } from 'react';
import get from 'lodash-es/get';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import {
  OVCStoreDataContext,
  OVCReturnCartHeading,
  OVCReturnCartActionRow,
  opacityToSolidColor,
  OVCCheckbox,
} from 'outvio-ui';
import { useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import ReturnCartAddressModal from './ReturnCartAddressModal';
import getCourierPickupLocation from '../../utils/getCourierPickupLocation';

import { RootState } from '../../reducers';
import { Package, ReturnLabelMode, ReturnAddress } from './returnCartTypes';
import { OrderData } from '../../types/general';
import { Address } from 'outvio-ui/lib/types/Address';

interface StyleProps {
  textColor: string;
  tenPctText: string;
  btnBg: string;
  btnText: string;
  hasPickup: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    width: '100%',

    display: 'grid',
    gridTemplateColumns: (props) => (props.hasPickup ? '1fr 2fr' : '1fr'),
    gridTemplateRows: '81px 1fr',
    gridColumnGap: '30px',
    gridRowGap: '15px',

    gridTemplateAreas: (props) =>
      props.hasPickup ? '"address costs" "address desc"' : '"costs" "desc"',

    color: (props) => props.textColor,

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: (props) =>
        props.hasPickup ? '"address" "costs" "desc"' : '"costs" "desc"',
    },
  },
  addressCell: {
    gridArea: 'address',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  costsCell: {
    gridArea: 'costs',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  descCell: {
    gridArea: 'desc',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  borderBox: {
    position: 'relative',
    height: '100%',
    padding: '15px',

    borderRadius: '5px',
    border: (props) => `1px solid ${props.tenPctText}`,
  },
  borderBoxCost: {
    width: 'max-content',
    padding: '15px 36px',
    alignItems: 'center !important',
  },
  borderBoxAddress: {
    '& > p': {
      margin: 0,
      marginBottom: '20px',
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  addressTitle: {
    fontWeight: 600,
    marginBottom: '0 !important',
  },
  costsTitle: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: '22px',
  },
  costsValue: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: '27px',
  },
  descStyles: {
    '& > p': {
      marginBottom: '25px',
      fontSize: '16px',
      lineHeight: '22px',

      '&:first-of-type': {
        textTransform: 'uppercase',
        fontWeight: 700,
      },

      '&:nth-of-type(2)': {
        whiteSpace: 'pre-line',
      },
    },
  },
  actionBox: {
    width: '100%',
    padding: '15px 30px',
    marginTop: 'auto',

    borderRadius: '5px',
    backgroundColor: (props) => props.btnBg,
    color: (props) => props.btnText,

    display: 'grid',
    gridTemplateColumns: '1fr 43px',
    gridColumnGap: '4px',

    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  termsLink: {
    fontWeight: 700,
    color: (props) => props.btnText,
    textDecoration: 'underline',

    '&:hover': {
      color: (props) => props.btnText,
    },
  },
  editHolder: {
    position: 'absolute',
    top: '6px',
    right: '6px',

    fontSize: '35px',
    color: (props) => props.textColor,

    display: 'flex',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface IReturnCartStep3 {
  goToPrevPage(): void;
  goToNextPage(value: string): void;
  returnCost: number;
  hasPickup: boolean;
  labelMode: ReturnLabelMode;
  order: OrderData;
  returnPackages: Package[];
  onSaveAddress(value: ReturnAddress): void;
}

const ReturnCartStep3 = ({
  goToPrevPage,
  returnCost,
  hasPickup,
  labelMode,
  order,
  onSaveAddress,
  goToNextPage,
  returnPackages,
}: IReturnCartStep3) => {
  const intl = useIntl();

  const { storeData } = useContext(OVCStoreDataContext);
  const userData = useSelector((state: RootState) => state.user.data);

  const tenPctText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.1,
        storeData.portalSettings.colors.secondaryBg,
      ),
    [storeData],
  );

  const classes = useStyles({
    textColor: storeData.portalSettings.colors.primaryText,
    tenPctText,
    btnBg: storeData.portalSettings.colors.btnBg,
    btnText: storeData.portalSettings.colors.btnText,
    hasPickup,
  });

  const [isAddressModalOpen, setAddressModal] = useState(false);

  const INITIAL_VALUES: {
    addressAccepted: boolean;
    termsAccepted: boolean;
    pickupAddress: Address;
  } = {
    addressAccepted: false,
    termsAccepted: false,
    pickupAddress: order.shipping.shipments.shipping[0].delivery,
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors: Record<string, string> = {};

        // only needed if hasPickup, otherwise we dont show it
        if (hasPickup && values.addressAccepted === false) {
          errors.addressAccepted = 'required';
        }

        if (values.termsAccepted === false) {
          errors.termsAccepted = 'required';
        }

        return errors;
      }}
      onSubmit={(values) => {
        onSaveAddress(values.pickupAddress);
        goToNextPage('instructions');
      }}
    >
      {({ values, setFieldValue, touched, errors, handleSubmit }) => {
        return (
          <>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <OVCReturnCartHeading
                textId={`ovc.return-cart.inst.outvio.heading.confirm-${
                  hasPickup ? 'pickup' : 'return'
                }`}
              />

              <div className={classes.container}>
                {hasPickup && (
                  <div className={classes.addressCell}>
                    <div className={cn(classes.borderBox, classes.borderBoxAddress)}>
                      <div className={classes.editHolder} onClick={() => setAddressModal(true)}>
                        <EditIcon color="inherit" fontSize="inherit" />
                      </div>

                      <p className={classes.addressTitle} style={{ marginBottom: '20px' }}>
                        <FormattedMessage id="general.pickup-address" />
                      </p>
                      <p>
                        {values.pickupAddress.contactName}
                        <br />
                        {values.pickupAddress.address}
                        <br />
                        {values.pickupAddress.postcode} {values.pickupAddress.city}
                        <br />
                        {values.pickupAddress.country}
                      </p>

                      <p className={classes.addressTitle}>
                        <FormattedMessage id="general.date-time-pickup" />
                      </p>
                      <p>
                        {`${intl.formatMessage({ id: 'or.from' })}: ${
                          values.pickupAddress.pickupTime
                            ? values.pickupAddress.pickupTime.from
                            : '08:00'
                        }`}
                        <br />
                        {`${intl.formatMessage({ id: 'or.to' })}: ${
                          values.pickupAddress.pickupTime
                            ? values.pickupAddress.pickupTime.to
                            : '20:00'
                        }`}
                      </p>

                      <p className={classes.addressTitle}>
                        <FormattedMessage id="general.contact-phone-number" />
                      </p>
                      <p>{values.pickupAddress.contactPhone}</p>

                      <p className={classes.addressTitle}>
                        <FormattedMessage id="general.comments-courier" />
                      </p>
                      <p>{values.pickupAddress.comments || '-'}</p>

                      <div className={classes.actionBox}>
                        <div>
                          <FormattedMessage id="general.confirm-address" />
                        </div>
                        <div>
                          <OVCCheckbox
                            checked={values.addressAccepted}
                            onChange={(e, checked) => {
                              setFieldValue('addressAccepted', checked);
                            }}
                            customColor={storeData.portalSettings.colors.btnText}
                            customBgColor={storeData.portalSettings.colors.btnBg}
                            error={Boolean(
                              get(touched, 'addressAccepted') && get(errors, 'addressAccepted'),
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className={classes.costsCell}>
                  <div className={cn(classes.borderBox, classes.borderBoxCost)}>
                    <p className={classes.costsTitle}>
                      {intl.formatMessage({ id: 'general.shipping-cost' })}:
                    </p>
                    <p className={classes.costsValue}>
                      {returnCost
                        ? `EUR ${returnCost.toFixed(2)}`
                        : intl.formatMessage({ id: 'general.free' })}
                    </p>
                  </div>
                </div>
                <div className={classes.descCell}>
                  <div className={cn(classes.borderBox, classes.descStyles)}>
                    <FormattedMessage id="retcart.inst.headline.important" tagName="p" />
                    {hasPickup ? (
                      <>
                        {labelMode === 'noLabel' && (
                          <FormattedMessage
                            id="retcart.inst.hasPickup.noLabel"
                            values={{
                              lineBreak: <br />,
                              fillerOptionally: (
                                <FormattedMessage
                                  id="retcart.inst.hasPickup.noLabel.fillerOptionally"
                                  tagName="u"
                                />
                              ),
                            }}
                            tagName="p"
                          />
                        )}
                        {labelMode === 'byEmail' && (
                          <FormattedMessage
                            id="retcart.inst.hasPickup.byEmail"
                            values={{
                              lineBreak: <br />,
                              fillerNotice: (
                                <FormattedMessage
                                  id="retcart.inst.hasPickup.byEmail.fillerNotice"
                                  tagName="b"
                                  values={{ email: userData?.email }}
                                />
                              ),
                            }}
                            tagName="p"
                          />
                        )}
                        {labelMode === 'provided' && (
                          <FormattedMessage
                            id="retcart.inst.hasPickup.provided"
                            values={{ lineBreak: <br /> }}
                            tagName="p"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {labelMode === 'byEmail' && (
                          <FormattedMessage
                            id="retcart.inst.noPickup.byEmail"
                            values={{
                              lineBreak: <br />,
                              fillerNotice: (
                                <FormattedMessage
                                  id="retcart.inst.noPickup.byEmail.fillerNotice"
                                  tagName="b"
                                  values={{ email: userData?.email }}
                                />
                              ),
                              fillerCourierHomepage: getCourierPickupLocation(
                                returnPackages,
                                'retcart.inst.noPickup.byEmail.fillerCourierHomepage',
                              ),
                            }}
                            tagName="p"
                          />
                        )}
                        {labelMode === 'provided' && (
                          <FormattedMessage
                            id="retcart.inst.noPickup.provided"
                            values={{
                              lineBreak: <br />,
                              fillerCourierHomepage: getCourierPickupLocation(
                                returnPackages,
                                'retcart.inst.noPickup.provided.fillerCourierHomepage',
                              ),
                            }}
                            tagName="p"
                          />
                        )}
                      </>
                    )}
                    <div className={classes.actionBox}>
                      <div>
                        <FormattedMessage
                          id="retcart.confirm-return-terms"
                          values={{
                            terms:
                              !!storeData.termsUrl || storeData.return.termsAndConditionsLink ? (
                                <a
                                  href={
                                    storeData.termsUrl || storeData.return.termsAndConditionsLink
                                  }
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  className={classes.termsLink}
                                >
                                  {intl.formatMessage({ id: 'retcart.confirm-return-terms.terms' })}
                                </a>
                              ) : (
                                <FormattedMessage id="retcart.confirm-return-terms.terms" />
                              ),
                          }}
                        />
                      </div>
                      <div>
                        <OVCCheckbox
                          checked={values.termsAccepted}
                          onChange={(e, checked) => {
                            setFieldValue('termsAccepted', checked);
                          }}
                          customColor={storeData.portalSettings.colors.btnText}
                          customBgColor={storeData.portalSettings.colors.btnBg}
                          error={Boolean(
                            get(touched, 'termsAccepted') && get(errors, 'termsAccepted'),
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <OVCReturnCartActionRow useConfirm onBackClick={goToPrevPage} />

              {isAddressModalOpen && (
                <ReturnCartAddressModal
                  handleClose={() => setAddressModal(false)}
                  initialAddress={values.pickupAddress}
                  onChangeAddress={(address) => setFieldValue('pickupAddress', address)}
                />
              )}
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default ReturnCartStep3;
