import { CustomerReturnMethod, CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';

import InstructionsToClientShop from './InstructionsToClientShop';
import InstructionsOwnCourier from './InstructionsOwnCourier';
import InstructionsOutvio from './InstructionsOutvio';
import InstructionsExchange from './InstructionsExchange';

import {
  ReturnWarehouse,
  ReturnLabelMode,
  StateProduct,
  Package,
  ReturnAddress,
  ReturnSavedPackagesObj,
} from './returnCartTypes';
import { OrderData } from '../../types/general';

interface IReturnCartStepInstructions {
  returnMethod: null | CustomerReturnMethod;
  returnWarehouse: ReturnWarehouse;
  refundMethod: null | CustomerReimbursement;
  goToNextPage(value: string): void;
  goToPrevPage(value: string): void;
  returnCost: number;
  hasPickup: boolean;
  setExchangeComment(value: string): void;
  labelMode: ReturnLabelMode;
  productsReturning: StateProduct[];
  returnPickupMode: null | string;
  order: OrderData;
  returnPackages: Package[];
  onSavePackages(value: ReturnSavedPackagesObj): void;
  onSaveAddress(value: ReturnAddress): void;
}

const ReturnCartStepInstructions = ({
  returnMethod,
  goToNextPage,
  goToPrevPage,
  order,
  productsReturning,
  returnPackages,
  onSavePackages,
  returnCost,
  onSaveAddress,
  hasPickup,
  labelMode,
  returnWarehouse,
  setExchangeComment,
  refundMethod,
  returnPickupMode,
}: IReturnCartStepInstructions) => {
  return (
    <>
      {returnMethod === 'toClientShop' && (
        <InstructionsToClientShop
          order={order}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
        />
      )}
      {returnMethod === 'ownCourier' && (
        <InstructionsOwnCourier
          order={order}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          returnWarehouse={returnWarehouse}
        />
      )}
      {returnMethod === 'exchange' && (
        <InstructionsExchange
          setExchangeComment={setExchangeComment}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
        />
      )}
      {returnMethod === 'outvio' && (
        <InstructionsOutvio
          order={order}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          productsReturning={productsReturning}
          returnPackages={returnPackages}
          onSavePackages={onSavePackages}
          returnCost={returnCost}
          onSaveAddress={onSaveAddress}
          hasPickup={hasPickup}
          labelMode={labelMode}
          refundMethod={refundMethod}
          returnPickupMode={returnPickupMode}
        />
      )}
    </>
  );
};

export default ReturnCartStepInstructions;
