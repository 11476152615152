import { FormattedMessage } from 'react-intl';
import { Package } from '../pages/ReturnCart/returnCartTypes';

export default (returnPackages: Package[], messageId: string) => {
  let linkHref = '';
  if (returnPackages && returnPackages[0] && returnPackages[0].courier) {
    switch (returnPackages[0].courier.toLowerCase()) {
      case 'publiccorreos':
        linkHref =
          'https://www.correos.es/ss/Satellite/site/aplicacion-1349167812848-localiza_busca_encuentra/detalle_app-sidioma=es_ES';
        break;
      case 'dhl':
        linkHref = 'https://locator.dhl.com/';
        break;
      case 'dpd':
        linkHref = 'https://www.dpd.com/ee/parcelshops/show?searchtext=tallinn';
        break;
      case 'omniva':
        linkHref = 'https://www.omniva.ee/abi/kaart';
        break;
      case 'smartpost':
        linkHref = 'https://www.smartpost.ee/pakiautomaatide-asukohad';
        break;
      case 'ups':
        linkHref = 'https://www.ups.com/mobile/locator?loc=es_ES';
        break;
      case 'mondialrelay':
        linkHref = 'https://www.inpost.es/buscar-el-punto-pack-mas-cercano/';
        break;
      case 'seur_atlas':
        linkHref = 'https://www.seur.com/es/shop2shop/';
        break;
      case 'dhl_paket_germany':
        linkHref = 'https://www.dhl.de/de/privatkunden/pakete-empfangen/an-einem-abholort-empfangen/filiale-empfang.html';
        break;
      case 'dpd_germany':
        linkHref = 'https://www.dpd.com/de/de/pickup-paketshops/';
        break;
      default:
        break;
    }
  }

  if (linkHref === '') {
    return <FormattedMessage id={messageId} />;
  }

  return (
    <a href={linkHref} target="_blank" rel="noopener noreferrer">
      <FormattedMessage id={messageId} />
    </a>
  );
};
