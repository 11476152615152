function value(val: boolean): string {
  return val ? '1' : '0';
}

function setCookie({ host, isActive, isPreview, isTracking }: DomainData): void {
  document.cookie = `portal-v2=${host}:${value(isActive)}:${value(isPreview)}:${value(
    isTracking,
  )};path=/`;
}

const url = RETURN_PORTAL_URL || `${OUTVIO_API_URL}/returns`;

interface DomainData {
  isPreview: boolean;
  isActive: boolean;
  isTracking: boolean;
  host: string;
}

export async function isPortalV2(): Promise<DomainData> {
  let host = '';
  try {
    host = window.location.host;

    if (host.includes('localhost')) {
      host = DEV_BRAND_HOST;
    }

    const req = await fetch(`${url}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'brand-host': host,
      },
      body: JSON.stringify({
        query: `
            query brand_findByUri {
              data: brand_findByUri {
                isTracking
                brand {
                  isActive
                }  
              }
            }
            `,
      }),
    });
    const { data } = await req.json();

    const domainData = {
      isPreview: window.location.pathname === '/preview',
      isActive: true,
      isTracking: data?.data?.isTracking,
      host,
    };

    setCookie(domainData);

    if (domainData.isTracking) return domainData;

    console.log('!!!', {
      ...domainData,
      env: process.env.NODE_ENV,
    });
    if (process.env.NODE_ENV !== 'development') {
      setTimeout(() => location.reload(), 1000);
    }

    return new Promise((resolve) => {
      setTimeout(() => resolve(domainData), 5000);
    });
  } catch (err) {
    console.error('isPortalV2', err);
  }

  return {
    isPreview: false,
    isActive: false,
    isTracking: false,
    host,
  };
}
