import { useState, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash-es/get';
import { FormikProps } from 'formik';
import { OVCStoreDataContext, opacityToSolidColor, OVCButton, OVCInput } from 'outvio-ui';

import { makeStyles, Theme } from '@material-ui/core';

import PackageIcon from './PackageIcon';

import { ReturnStep2FormValues, ReturnStep2Package } from './returnCartTypes';

interface StyleProps {
  textColor: string;
  alertColor: string;
  tenPctText: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: '525px',
    padding: '24px',
    paddingLeft: '180px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: (props) => `1px solid ${props.textColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '& p': { margin: 0 },
    '@media (max-width: 500px)': { paddingLeft: '24px', paddingTop: '80px' },
  },
  iconHolder: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': { height: '80px', width: '80px' },
    '@media (max-width: 500px)': {
      width: 'unset',
      height: '80px',
      bottom: 'unset',
      right: 0,
      '& svg': { height: '60px', width: '60px' },
    },
  },
  packTitle: {
    lineHeight: '22px',
    fontSize: '16px',
    fontWeight: 600,
  },
  packSizeDisplay: {
    lineHeight: '22px',
    fontSize: '16px',
  },
  actionText: {
    '& span': {
      fontSize: '11px',
      fontWeight: '600',
      lineHeight: '15px',
      color: (props) => props.textColor,
      display: 'inline-block',
      marginRight: '8px',
      cursor: 'pointer',
      userSelect: 'none',
      '&:last-child': { marginRight: 0 },
    },
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    '& > span': {
      color: (props) => props.textColor,
    },
  },
  reducedElement: {
    paddingLeft: '9px',
    paddingRight: '9px',
  },
});

interface IReturnPackage {
  index: number;
  pack: ReturnStep2Package;
  handleRemove(): void;
  disabled?: boolean;
}

const ReturnPackage = ({
  pack,
  handleRemove,
  index,
  values,
  errors,
  setFieldValue,
  disabled = false,
}: IReturnPackage &
  Pick<FormikProps<ReturnStep2FormValues>, 'values' | 'errors' | 'setFieldValue'>) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const tenPctText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.1,
        storeData.portalSettings.colors.secondaryBg,
      ),
    [storeData],
  );

  const classes = useStyles({
    textColor: storeData.portalSettings.colors.primaryText,
    alertColor: storeData.portalSettings.colors.alertColor,
    tenPctText,
  });
  const intl = useIntl();

  const [mode, setMode] = useState<'display' | 'edit'>('display');

  return (
    <div className={classes.container}>
      <div className={classes.iconHolder}>
        <PackageIcon
          foreColor={storeData.portalSettings.colors.primaryText}
          bgColor={storeData.portalSettings.colors.secondaryBg}
        />
      </div>
      {mode === 'display' && (
        <>
          {pack.defaultSuggested ? (
            <p className={classes.packTitle}>
              {intl.formatMessage({ id: 'retcart.pack.default-title.display' })}
            </p>
          ) : (
            <p className={classes.packTitle}>
              {intl.formatMessage({ id: 'retcart.pack.custom-title.display' })}
            </p>
          )}
          <p
            className={classes.packSizeDisplay}
          >{`${pack.length} x ${pack.width} x ${pack.height} cm`}</p>
          <p className={classes.actionText}>
            <span onClick={() => setMode('edit')}>
              {intl.formatMessage({ id: 'retcart.pack.change-size' })}
            </span>
            <span onClick={handleRemove}>{intl.formatMessage({ id: 'retcart.pack.remove' })}</span>
          </p>
        </>
      )}
      {mode === 'edit' && (
        <>
          <p className={classes.packTitle}>
            {intl.formatMessage({ id: 'retcart.pack.custom-title.edit' })}
          </p>
          <div className={classes.inputRow}>
            <OVCInput
              name={`packages[${index}].length`}
              value={values.packages[index].length}
              onChange={(e) => {
                setFieldValue(`packages[${index}].length`, e.target.value);
                setFieldValue(`packages[${index}].defaultSuggested`, false);
              }}
              placeholder={intl.formatMessage({ id: 'general.length' })}
              error={!!get(errors, `packages[${index}].length`)}
              className={classes.reducedElement}
              disabled={disabled}
            />
            <span>x</span>
            <OVCInput
              name={`packages[${index}].width`}
              value={values.packages[index].width}
              onChange={(e) => {
                setFieldValue(`packages[${index}].width`, e.target.value);
                setFieldValue(`packages[${index}].defaultSuggested`, false);
              }}
              placeholder={intl.formatMessage({ id: 'general.width' })}
              error={!!get(errors, `packages[${index}].width`)}
              className={classes.reducedElement}
              disabled={disabled}
            />
            <span>x</span>
            <OVCInput
              name={`packages[${index}].height`}
              value={values.packages[index].height}
              onChange={(e) => {
                setFieldValue(`packages[${index}].height`, e.target.value);
                setFieldValue(`packages[${index}].defaultSuggested`, false);
              }}
              placeholder={intl.formatMessage({ id: 'general.height' })}
              error={!!get(errors, `packages[${index}].height`)}
              className={classes.reducedElement}
              disabled={disabled}
            />
            <OVCButton
              onClick={() => setMode('display')}
              className={classes.reducedElement}
              disabled={disabled}
            >
              {intl.formatMessage({ id: 'account.save' })}
            </OVCButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ReturnPackage;
