import request from './request';

export const requestLogout = () =>
  request(`${OUTVIO_API_URL}/logout`, { method: 'GET', secure: true });

export const requestUser = () =>
  request(`${OUTVIO_API_URL}/customer/current-user`, { method: 'GET', secure: true });

export const updateChangePassword = (data: { oldPassword: string; newPassword: string }) =>
  request(`${OUTVIO_API_URL}/user/change-password`, { method: 'POST', data, secure: true });

export const requestLoginWithEmail = (data: {
  email?: string;
  orderIdentifier?: string;
  clientIdentifier?: string;
  language: string;
}) => request(`${OUTVIO_API_URL}/identity-verification`, { method: 'POST', data });
