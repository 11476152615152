import { useState, useContext } from 'react';
import { OVCStoreDataContext } from 'outvio-ui';
import { CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';

import ReturnCartStep2 from './ReturnCartStep2';
import ReturnCartStep3 from './ReturnCartStep3';

import {
  Package,
  ReturnLabelMode,
  ReturnSavedPackagesObj,
  StateProduct,
  ReturnAddress,
} from './returnCartTypes';
import { OrderData } from '../../types/general';

interface IInstructionsOutvio {
  goToPrevPage(value: string): void;
  goToNextPage(value: string): void;
  refundMethod: null | CustomerReimbursement;
  returnCost: number;
  hasPickup: boolean;
  labelMode: ReturnLabelMode;
  productsReturning: StateProduct[];
  returnPickupMode: null | string;
  order: OrderData;
  returnPackages: Package[];
  onSavePackages(value: ReturnSavedPackagesObj): void;
  onSaveAddress(value: ReturnAddress): void;
}

const InstructionsOutvio = ({
  order,
  goToPrevPage,
  goToNextPage,
  productsReturning,
  returnPackages,
  onSavePackages,
  returnCost,
  onSaveAddress,
  hasPickup,
  labelMode,
  refundMethod,
  returnPickupMode,
}: IInstructionsOutvio) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const [currentStep, setCurrentStep] = useState<'packaging' | 'address'>('packaging');

  return (
    <>
      {currentStep === 'packaging' && (
        <ReturnCartStep2
          goToNextPage={() => setCurrentStep('address')}
          goToPrevPage={goToPrevPage}
          order={order}
          productsReturning={productsReturning}
          returnPackages={returnPackages}
          onSavePackages={onSavePackages}
          refundMethod={refundMethod}
          returnPickupMode={returnPickupMode}
        />
      )}
      {currentStep === 'address' && (
        <ReturnCartStep3
          goToPrevPage={() => {
            if (storeData.return.customerCanDefineReturnPackaging) {
              setCurrentStep('packaging');
            } else {
              goToPrevPage('instructions');
            }
          }}
          goToNextPage={goToNextPage}
          order={order}
          returnPackages={returnPackages}
          returnCost={returnCost}
          onSaveAddress={onSaveAddress}
          hasPickup={hasPickup}
          labelMode={labelMode}
        />
      )}
    </>
  );
};

export default InstructionsOutvio;
