import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { OVCStoreDataContext, OVCReturnCartActionRow, OVCReturnCartHeading } from 'outvio-ui';

import { makeStyles, Theme } from '@material-ui/core';

import { OrderData } from '../../types/general';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '260px',

    color: (props) => props.textColor,

    '& > p': {
      marginBottom: 0,
    },
  },
  linkStyle: {
    fontWeight: 600,
    textDecoration: 'underline',

    color: (props) => props.textColor,
    '&:hover': {
      color: (props) => props.textColor,
    },
  },
});

interface IInstructionsToClientShop {
  goToPrevPage(value: string): void;
  goToNextPage(value: string): void;
  order: OrderData;
}

const InstructionsToClientShop = ({
  order,
  goToPrevPage,
  goToNextPage,
}: IInstructionsToClientShop) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });

  const returnableUntil = moment
    .utc(order.deliverDate)
    .add(storeData.return.days, 'days')
    .format('DD.MM.YYYY');

  return (
    <>
      <OVCReturnCartHeading textId="retcart.inst.toClientShop.heading" />

      <div className={classes.container}>
        <p style={{ fontWeight: 600 }}>
          <FormattedMessage id="retcart.inst.toClientShop.text1" />
        </p>
        <FormattedMessage
          id="retcart.inst.toClientShop.text2"
          values={{ dueDate: returnableUntil }}
          tagName="p"
        />
        <br />
        <FormattedMessage
          id="retcart.inst.toClientShop.text3"
          tagName="p"
          values={{
            link: (
              <a
                href={storeData.return.toClientShopUrl}
                target="_blank"
                className={classes.linkStyle}
              >
                <FormattedMessage id="retcart.inst.toClientShop.text3.link" />
              </a>
            ),
          }}
        />
      </div>
      <OVCReturnCartActionRow
        onBackClick={() => goToPrevPage('instructions')}
        customSubmitAction={() => goToNextPage('instructions')}
      />
    </>
  );
};

export default InstructionsToClientShop;
