import { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  OVCStoreDataContext,
  OVCReturnCartActionRow,
  OVCTextarea,
  OVCExchangeHeadingText,
  OVCExchangeText,
} from 'outvio-ui';

import { makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '260px',

    color: (props) => props.textColor,

    '& > p': {
      marginBottom: 0,
    },
  },
});

interface IInstructionsExchange {
  goToPrevPage(value: string): void;
  goToNextPage(value: string): void;
  setExchangeComment(value: string): void;
}

const InstructionsExchange = ({
  goToPrevPage,
  goToNextPage,
  setExchangeComment,
}: IInstructionsExchange) => {
  const intl = useIntl();

  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });

  const [comment, setComment] = useState('');

  return (
    <>
      <OVCExchangeHeadingText />
      <div className={classes.container}>
        <OVCExchangeText customTextId="return-inst-exchange-subhead" style={{ fontWeight: 600 }} />
        <OVCExchangeText customTextId="return-inst-exchange-note" />
        <OVCTextarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={intl.formatMessage({ id: 'retcart.inst.exchange.text.placeholder' })}
          rows={4}
          style={{ minWidth: 'unset', width: '400px' }}
        />
        <br />

        <OVCExchangeText
          customTextId="return-inst-exchange-desc"
          style={{ whiteSpace: 'pre-line' }}
        />
      </div>

      <OVCReturnCartActionRow
        onBackClick={() => goToPrevPage('instructions')}
        submitDisabled={comment.length === 0}
        customSubmitAction={() => {
          setExchangeComment(comment);
          goToNextPage('instructions');
        }}
        useConfirm
      />
    </>
  );
};

export default InstructionsExchange;
