import { useEffect } from 'react';
import { OVCLoader } from 'outvio-ui';
import { useIntl } from 'react-intl';
import OVLocalCache from 'utils/storage';
import { requestLogout } from '../../utils/api';
import getUrlParamFromLocale from '../../utils/getUrlParamFromLocale';

const Logout = () => {
  const intl = useIntl();

  useEffect(() => {
    requestLogout()
      .then(() => OVLocalCache.purgeNonCache())
      .then(() => {
        window.location.assign(`/${getUrlParamFromLocale(intl.locale)}`);
      })
      .catch((err) => {
        console.log(err);
        window.location.assign(`/${getUrlParamFromLocale(intl.locale)}`);
      });
  }, []);

  return <OVCLoader />;
};

export default Logout;
