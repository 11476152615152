import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { OVCAlert, OVCLangSwitcherContext, OVCStoreDataContext } from 'outvio-ui';
import {
  Features,
  IPortalSettings,
  TrackingPage,
  TrackingPageLoader,
  trackingToV2,
  SourceDestinationAddress,
  SplitOrderTabs,
  PackagesListTabs,
  ITrackingData,
} from 'tracking-fe';
import { requestChangeDeliveryAddress, requestSendRating } from 'utils/api';
import { useTrackingData } from 'utils/useTrackingData';
import { useTrackingSteps } from 'utils/useTrackingSteps';
import { useSplitOrder } from 'utils/useSplitOrder';
import { Product } from '../../types/general';
import displayCurrency from 'utils/displayCurrency';

const useTrackingViewV2 = ({ otn }: { otn: string }) => {
  const { appLang } = useContext(OVCLangSwitcherContext);
  const {
    storeData: { portalV2 },
  } = useContext(OVCStoreDataContext);
  const { storeData } = useContext(OVCStoreDataContext);
  // By default we use the otn we already have
  const [currentOtn, setCurrentOtn] = useState<string>(otn);
  const sendRatingRequest = useCallback(
    (rating: number) => {
      requestSendRating({ token: currentOtn, data: { rating } });
    },
    [currentOtn],
  );
  const sourceTracking = useTrackingData({ otn: currentOtn });
  const [source, setSource] = useState<number>(0);
  // Current package index, by default the first one
  const [currentPackage, setCurrentPackage] = useState<number>(0);
  // Is the order a split order?
  const isSplit = useMemo(
    () => sourceTracking?.data?.isSplitOrder || false,
    [sourceTracking?.data],
  );
  // Is the order deleted?
  const isDeleted = useMemo(() => sourceTracking?.data?.isDeleted || false, [sourceTracking?.data]);

  const orderOtn = sourceTracking?.data?.orderOtn;
  const splitOrderData = useSplitOrder({
    otn: sourceTracking?.data ? orderOtn || otn : undefined,
    isSplit,
  });

  const firstFetch = useRef(false);
  useEffect(() => {
    // console.log('!!!', { orderOtn, otn, firstFetch: firstFetch.current });
    if (!firstFetch.current && orderOtn && otn !== orderOtn) {
      setCurrentOtn(orderOtn);
      firstFetch.current = true;
    }
  }, [orderOtn]);

  const sourceSteps = useTrackingSteps({ otn: currentOtn });
  const isMultiPackages = useMemo(
    () => (sourceSteps?.data?.tracking?.packages?.length || 0) > 1,
    [sourceSteps?.data],
  );
  const filteredSourceSteps = useMemo(() => {
    const current = sourceSteps?.data?.tracking?.packages?.[currentPackage];
    if (!isMultiPackages) return sourceSteps;
    return {
      ...sourceSteps,
      data: {
        tracking: {
          steps:
            sourceSteps?.data?.tracking?.steps?.filter?.((step) => step?.packageId === current) ||
            [],
        },
      },
    };
  }, [sourceSteps, currentPackage, isMultiPackages]);

  const portalSettings = JSON.parse(JSON.stringify(portalV2)) as IPortalSettings;
  const handleChangeDeliveryAddress = (data: Partial<SourceDestinationAddress>) =>
    requestChangeDeliveryAddress({ token: currentOtn, data });

  const features: Features = useMemo(
    () => ({
      trackingMap: storeData?.feature?.customizeMapMarkers,
      customDomains: storeData.feature?.customDomains,
    }),
    [storeData],
  );

  const trackingData = useMemo(() => {
    if (!sourceTracking?.data || !filteredSourceSteps.data) return null;
    return trackingToV2(sourceTracking?.data, filteredSourceSteps.data.tracking) || null;
  }, [sourceTracking?.data, filteredSourceSteps.data, source]);

  // Order Info in case of split order
  const orderInfo = useMemo(() => {
    const { data } = splitOrderData;
    if (!isSplit || !splitOrderData) {
      return null;
    }
    return {
      total: data?.reduce?.((acc, curr) => acc + curr?.total, 0) || 0,
      tax: data?.reduce?.((acc, curr) => acc + curr?.tax, 0) || 0,
      shipping: data?.reduce?.((acc, curr) => acc + curr?.shipping?.price, 0) || 0,
      currency: data?.[0]?.currency || 'EUR',
    };
  }, [splitOrderData, isSplit]);

  useEffect(() => {
    splitOrderData?.data && setCurrentOtn(splitOrderData?.data?.[source]?.otn || otn);
  }, [source]);

  useEffect(() => {
    if (!isSplit) return;
    setSource(splitOrderData?.data?.findIndex?.((item) => item?.otn === currentOtn) || 0);
  }, [currentOtn, isSplit, splitOrderData?.data]);

  const trackingDataIntegrated = useMemo(() => {
    if (!isSplit || !orderInfo) return trackingData;
    return {
      ...trackingData,
      orderInfo: {
        ...trackingData?.orderInfo,
        shippingPrice:
          displayCurrency(orderInfo?.shipping, orderInfo?.currency) ||
          trackingData?.orderInfo?.shippingPrice ||
          0,
        orderTotal:
          displayCurrency(orderInfo?.total, orderInfo?.currency) ||
          trackingData?.orderInfo?.orderTotal ||
          0,
        vat:
          displayCurrency(orderInfo?.tax, orderInfo?.currency) || trackingData?.orderInfo?.vat || 0,
      },
    };
  }, [trackingData, isSplit, orderInfo]);

  return {
    currentOtn,
    appLang,
    error: (sourceTracking.error || filteredSourceSteps.error || null) as any,
    isLoading: sourceTracking.isLoading || filteredSourceSteps.isLoading || false,
    trackingData: trackingDataIntegrated,
    portalSettings,
    storeData,
    features,
    sendRatingRequest,
    handleChangeDeliveryAddress,
    source,
    setSource,
    isSplit,
    isDeleted,
    splitCurrency: orderInfo?.currency,
    packages: splitOrderData?.data || [], // Split Orders
    currentPackage,
    setCurrentPackage,
    isMultiPackages,
    shipments: sourceSteps?.data?.tracking?.packages || [], // Multi Packages
  };
};

const TrackingViewV2 = ({ otn }: { otn: string }) => {
  const [trackingViewLoading, setTrackingViewLoading] = useState(false);
  const {
    currentOtn,
    trackingData,
    packages,
    error,
    splitCurrency,
    appLang,
    isLoading,
    portalSettings,
    features,
    sendRatingRequest,
    handleChangeDeliveryAddress,
    setSource,
    isSplit,
    isDeleted,
    isMultiPackages,
    currentPackage,
    setCurrentPackage,
    shipments,
  } = useTrackingViewV2({
    otn,
  });

  const packagesToShow = useMemo(() => {
    if (!isSplit) return [];
    return (
      packages?.map?.((p) => ({
        id: p.otn,
      })) || []
    );
  }, [isSplit, packages]);

  const otherProducts = useMemo(() => {
    if (!isSplit) return [];
    return packages
      .reduce((acc, p) => {
        return [
          ...acc,
          ...p.products.map((product: Product) => {
            return {
              ...product,
              otn: p.otn,
              price: displayCurrency(product.price, splitCurrency),
              discountPrice: product.discountPrice
                ? displayCurrency(product.discountPrice, splitCurrency)
                : null,
            };
          }),
        ].filter((product) => product.otn !== currentOtn);
      }, [] as any[])
      .reduce((acc, curr) => {
        // aggregate products with same sku
        const index = acc.findIndex((p: Product) => p.sku === curr.sku);
        if (index === -1) {
          return [...acc, curr];
        }
        return [
          ...acc.slice(0, index),
          {
            ...acc[index],
            quantity: acc[index].quantity + curr.quantity,
          },
          ...acc.slice(index + 1),
        ];
      }, [] as any[]);
  }, [isSplit, packages, currentOtn, splitCurrency]);

  if (isLoading || trackingViewLoading) return <TrackingPageLoader />;

  if (error || !trackingData || !portalSettings || !features) {
    return <OVCAlert>{error?.message || 'There was an error loading your data.'}</OVCAlert>;
  }

  return (
    <>
      <TrackingPage
        isEdit={false}
        trackingPageId={otn}
        trackingData={trackingData as ITrackingData}
        lang={appLang}
        portalSettings={portalSettings}
        googleMapsKey={GOOGLE_MAP_API_KEY}
        features={features}
        handleRate={(rating) => sendRatingRequest(rating)}
        handleChangeDeliveryAddress={handleChangeDeliveryAddress}
        shipmentHeaderComponent={
          isSplit && (
            <SplitOrderTabs
              packages={packagesToShow}
              selectedPackage={
                packagesToShow?.find?.((p) => p.id === currentOtn) || { id: currentOtn }
              }
              handleMultiplePackages={(n: number) => {
                setTrackingViewLoading(true);
                setSource(n);
                setTimeout(() => {
                  setTrackingViewLoading(false);
                }, 1000);
              }}
              lang={appLang}
            />
          )
        }
        packagesHeaderComponent={
          isMultiPackages && (
            <PackagesListTabs
              isVisible={isMultiPackages}
              packages={shipments || []}
              currentPackage={shipments?.[currentPackage]}
              setCurrentPackage={setCurrentPackage}
              lang={appLang}
            />
          )
        }
        isSplitOrder={isSplit}
        othersProducts={otherProducts}
        isDeleted={isDeleted}
      />
    </>
  );
};

export default TrackingViewV2;
