import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import OVLocalCache from 'utils/storage';
import { CustomerStoreData } from 'outvio-ui/lib/types/CustomerStoreData';
import { EMPTY_STORE_DATA } from 'outvio-ui';

import { requestUser, requestStoreInfo } from '../utils/api';
import { AppThunk } from '../store';
import checkAccessibility from './checkAccessibility';
import { userLoaded } from './user';

const v2DefaultColors = {
  primaryText: '#00363D',
  primaryBg: '#FFFFFF',
  secondaryBg: '#FFFFFF',
  btnText: '#FFFFFF',
  btnBg: '#4BB4B3',
  alertColor: '#FF9800',

  // Map related
  mapBase: '#E0F2F1',
  mapPath: '#C6DCDD',
  mapText: '#00363D',
  mapPin: '#4BB4B3', // new, TODO: define this color
};
interface AppState {
  isInitialized: boolean;
  error: null | string;
  storeData: CustomerStoreData;
}

const appInitialState: AppState = {
  isInitialized: false,
  error: null,
  storeData: { ...EMPTY_STORE_DATA },
};

const fillPotentialMissingData = (storeData: CustomerStoreData) => {
  const colors = storeData.portalSettings.colors;
  const newData = {
    ...storeData,
    portalSettings: {
      ...storeData.portalSettings,
      colors: {
        ...storeData.portalSettings.colors,
        primaryText: get(colors, 'primaryText', v2DefaultColors.primaryText),
        primaryBg: get(colors, 'primaryBg', get(colors, 'pageBg') || v2DefaultColors.primaryBg),
        secondaryBg: get(
          colors,
          'secondaryBg',
          get(colors, 'qnaBg') || v2DefaultColors.secondaryBg,
        ),
        btnText: get(colors, 'btnText', get(colors, 'loginBg') || v2DefaultColors.btnText),
        btnBg: get(colors, 'btnBg', get(colors, 'loginBtn') || v2DefaultColors.btnBg),
        alertColor: get(
          colors,
          'alertColor',
          get(colors, 'mapBarWarning') || v2DefaultColors.alertColor,
        ),
        mapBase: get(colors, 'mapBase', get(colors, 'mapBase') || v2DefaultColors.mapBase),
        mapPath: get(colors, 'mapPath', get(colors, 'mapPath') || v2DefaultColors.mapPath),
        mapText: get(colors, 'mapText', get(colors, 'mapText') || v2DefaultColors.mapText),
        mapPin: get(colors, 'mapPin', get(colors, 'mapPin') || v2DefaultColors.mapPin),
      },
      desktopBg:
        storeData.portalSettings.desktopBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_returns_desktop.jpg',
      mobileBg:
        storeData.portalSettings.mobileBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_returns_desktop.jpg',
      trackingDesktopBg:
        storeData.portalSettings.trackingDesktopBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_tracking_desktop.jpg',
      trackingMobileBg:
        storeData.portalSettings.trackingMobileBg ||
        'https://outvio-prod-public.s3.eu-central-1.amazonaws.com/img/bg_tracking_desktop.jpg',
    },
    customer: {
      ...(storeData.customer
        ? storeData.customer
        : { orderVerification: false, identityVerification: true }),
    },
  };

  return newData;
};

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    appInitSuccess(state) {
      state.isInitialized = true;
    },
    appInitError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    storeDataLoaded(state, { payload }: PayloadAction<any>) {
      state.storeData = fillPotentialMissingData(payload);
    },
  },
});

export const { appInitSuccess, appInitError, storeDataLoaded } = appSlice.actions;

export const initApp =
  (isTracking?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      // Include otn in request for tracking pages
      const { hostname, pathname } = window.location;
      const split = pathname.split('/');

      const storeRes = await requestStoreInfo(
        pathname.includes('id') ? { otn: split[split.length - 1] } : {},
      );

      await OVLocalCache.purgeExpired();

      let checkResult = checkAccessibility(
        hostname,
        pathname,
        storeRes.data.feature.customDomains,
        storeRes.data.feature.customerPortal.available,
        storeRes.data?.customDomains,
      );

      if (isTracking) {
        checkResult = 'passthrough';
      }

      switch (checkResult) {
        case 'invalid-access':
          throw new Error('Invalid access.');
        case 'redirect-to-slug':
          if (storeRes.data.slug) {
            window.location.assign(`https://${storeRes.data.slug}.outvio.com${pathname}`);
            return;
          }
          throw new Error('Missing slug.');
        case 'redirect-to-homepage':
          if (storeRes.data.url) {
            window.location.assign(
              storeRes.data.url.includes('http') ? storeRes.data.url : `//${storeRes.data.url}`,
            );
            throw new Error('Invalid access. Redirecting.');
          }
          throw new Error('Invalid access.');
        case 'redirect-to-tracking': {
          const lang = window.location.href.split(/\//g).pop();
          if (lang?.length === 2) {
            window.location.assign(`https://${window.location.host}/${lang || 'en'}/tracking`);
            throw new Error('Invalid route, tracking custom domain detected, redirect to tracking');
          }
        }
      }

      dispatch(storeDataLoaded(storeRes.data));

      const token = await OVLocalCache.get('token');

      let userRes = null;
      if (token) {
        try {
          userRes = await requestUser();
        } catch (err) {
          console.log(err);
        }
      }

      if (userRes) {
        dispatch(userLoaded(userRes.user));
      }
      dispatch(appInitSuccess());
    } catch (err: any) {
      console.log(err);
      dispatch(appInitError(err.message || err.toString()));
    }
  };

export default appSlice.reducer;
