/*
                                 custom      no-custom + slug     no-custom + no-slug
customDomain tracking              pass               to slug              to default                
customDomain non-tracking          pass               to slug                 to home   
slug tracking                      pass                  pass              to default 
slug non-tracking                  pass                  pass                 to home     
default tracking                   pass                  pass                    pass    
default non-tracking            invalid               invalid                 invalid   

*/

const checkAccessibility = (
  hostname: string,
  pathname: string,
  canAccessCustomDomain: boolean,
  canAccessSlugDomain: boolean,
  customDomains: { tracking?: string; returns?: string },
) => {
  const isCustomDomain = !hostname.includes('outvio.com');
  const isSlugDomain = hostname.includes('outvio.com');
  const isTrackingPage = pathname.includes('/id');
  const isGenericPage = hostname.includes('outvio.com') && hostname.includes('customer');

  // Needed for cypress dry ui tests
  if (process.env.NODE_ENV === 'development' && hostname.includes('localhost')) {
    return 'passthrough';
  }

  // Disallow access on customer.outvio.com other than the tracking page
  if (isGenericPage && !isTrackingPage) {
    return 'invalid-access';
  }

  // default tracking should always pass through
  if (isGenericPage && isTrackingPage) {
    return 'passthrough';
  }

  // Redirect to slug
  if (isCustomDomain && !canAccessCustomDomain && canAccessSlugDomain) {
    // window.location.assign(`https://${res.data.slug}.outvio.com${window.location.pathname}`);
    return 'redirect-to-slug';
  }

  // Redirect to store url, non-tracking
  if ((isSlugDomain || isCustomDomain) && !canAccessSlugDomain && !isTrackingPage) {
    return 'redirect-to-homepage';
  }

  // Redirect to tracking if tracking domain and custom domain is enabled
  if (
    customDomains?.tracking &&
    isCustomDomain &&
    hostname.includes(customDomains.tracking?.replace(/https:\/\/|https:\/\//, ''))
  ) {
    return 'redirect-to-tracking';
  }

  // Everything is OK, go on
  return 'passthrough';
};

export default checkAccessibility;
