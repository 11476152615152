import { OVCLandingQnA, OVCHeroImage, OVCLoginBox } from 'outvio-ui';

import LoginBoxIdentity from '../../components/Common/Auth/LoginBoxIdentity';
import LoginBoxOrder from '../../components/Common/Auth/LoginBoxOrder';

const LoginPage = () => {
  return (
    <>
      <OVCHeroImage>
        <OVCLoginBox
          IdentityVerificationComponent={<LoginBoxIdentity />}
          OrderVerificationComponent={<LoginBoxOrder />}
        />
      </OVCHeroImage>
      <OVCLandingQnA qnaType="returns" />
    </>
  );
};

export default LoginPage;
