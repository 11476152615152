import { useState } from 'react';
import { OVCLandingQnA, OVCHeroImage, OVCAuthBox } from 'outvio-ui';

import TrackingView from '../../components/Common/TrackingView';

import DataInputPanel from './DataInputPanel';

const SpecificTrackingPage = () => {
  const [page, setPage] = useState<'input' | 'view'>('input');
  const [otn, setOTN] = useState('');

  if (page === 'input') {
    return (
      <>
        <OVCHeroImage useTrackingImg>
          <OVCAuthBox formWidth={460}>
            <DataInputPanel
              onGotOTN={(value: string) => {
                setOTN(value);
                setPage('view');
              }}
            />
          </OVCAuthBox>
        </OVCHeroImage>
        <OVCLandingQnA qnaType="tracking" />
      </>
    );
  }

  if (page === 'view') {
    return <TrackingView otn={otn} />;
  }

  return null;
};

export default SpecificTrackingPage;
