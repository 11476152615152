import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import checkSupportAndLoadPolyfills from './checksSupportAndLoadPolyfills';
import displaySupportError from './displaySupportError';
import store from './store';

import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { isPortalV2 } from './portal-v2';
import AppTracking from './AppTracking';

const theme = createMuiTheme({
  breakpoints: {
    // Default material-ui breakpoints as of 4.3.3
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
  },
  typography: {
    fontSize: 13,
  },
});

const queryClient = new QueryClient();

isPortalV2()
  .then(async (res) => {
    await checkSupportAndLoadPolyfills();
    return res;
  })
  .then(({ isTracking }) => {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <BrowserRouter>
              {isTracking ? <AppTracking isTracking={isTracking} /> : <App />}
            </BrowserRouter>
          </MuiThemeProvider>
        </Provider>
      </QueryClientProvider>,
      document.getElementById('root'),
    );
  })
  .catch(() => {
    displaySupportError();
  });
