import TrackingViewV2 from './TrackingViewV2';
import 'tracking-fe/dist/esm/index.css';
import 'iframe-resizer/js/iframeResizer.contentWindow';
interface ITrackingView {
  otn: string;
}

const TrackingView = ({ otn }: ITrackingView) => {
  return <TrackingViewV2 otn={otn} />;
};

export default TrackingView;
