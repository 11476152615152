import request from './request';
import { CustomerReimbursement, CustomerReturnMethod } from 'outvio-ui/lib/types/CustomerStoreData';

import { ReturnWarehouse, ReturnCourier, Package } from '../../pages/ReturnCart/returnCartTypes';
import { Product } from '../../types/general';

export interface ReturnPackageCloseResponse {
  withPickup: boolean;
  clientReturnCost: number;
  return: Package[];
}

export enum ReturnCourierMethod {
  COURIER = 'courier', // with actual courier
  TO_CLIENT_SHOP = 'toClientShop',
  OWN_COURIER = 'ownCourier',
  EXCHANGE = 'exchange',
}

export interface CloseReturnPackageParams {
  reimbursement: CustomerReimbursement;
  order: string; // order._id
  pickupAddress: string; // address._id
  withPickup: boolean | null;
  method: ReturnCourierMethod;
  preparedPackages: {
    package?: string; // id of packaging
    packaging?: { length: number; width: number; height: number };
    products: { _id: string; reason: string; images: string[]; comment: string }[];
  }[];
  returnComment?: string;
}

export interface ReturnListOrderShipment {
  _id: string;
  otn: string;
  packages: string[];
}

export interface ReturnListOrder {
  _id: string;
  id: string;
  originalId: string;
  otn: string;
  dateTime: string;
  currency: string;
  total: number;
  tax: number;
  shipping: {
    price: number;
    packages: {
      return: { _id: string; products: Product[] }[];
    };
    shipments: {
      return: ReturnListOrderShipment[];
    };
  };
}

export const requestReturns = (params: {
  hostname: string;
}): Promise<{ returns: ReturnListOrder[] }> =>
  request(`${OUTVIO_API_URL}/customer/returns`, { method: 'GET', secure: true, params });

export const insertReturn = (data: CloseReturnPackageParams): Promise<ReturnPackageCloseResponse> =>
  request(`${OUTVIO_API_URL}/return`, {
    method: 'POST',
    data,
    secure: true,
  });

export const deletePackage = (packageId: string) =>
  request(`${OUTVIO_API_URL}/return/${packageId}`, {
    method: 'DELETE',
    secure: true,
  });

export const requestReturnPrint = (data: {
  orderId: string;
  reimbursement: CustomerReimbursement; // TODO: test if can be removed. Looks like its not used here
}) =>
  request(`${OUTVIO_API_URL}/return/print`, {
    method: 'POST',
    data,
    secure: true,
  });

export const returnToClientShop = (
  data: CloseReturnPackageParams,
): Promise<ReturnPackageCloseResponse> =>
  request(`${OUTVIO_API_URL}/return/custom-courier`, {
    method: 'POST',
    data,
    secure: true,
  });

export const getReturnWarehouse = (
  otn: string,
): Promise<{ success: true; warehouse: ReturnWarehouse }> =>
  request(`${OUTVIO_API_URL}/return/${otn}/return-warehouse`, {
    method: 'GET',
    secure: true,
  });

export const handleExchangeFinish = (data: { orderId: string; message: string }) =>
  request(`${OUTVIO_API_URL}/return/exchange`, {
    method: 'POST',
    data,
    secure: true,
  });

export const resetOrderByOTN = (otn: string): Promise<{ success: true }> =>
  request(`${OUTVIO_API_URL}/customer/reset/order/${otn}`, {
    method: 'POST',
    secure: true,
  });

export const getReturnCourierOptions = (otn: string): Promise<{ couriers: ReturnCourier[] }> =>
  request(`${OUTVIO_API_URL}/return/${otn}/available-couriers`, {
    method: 'GET',
    secure: true,
  });

export const getReturnAllowedMethods = (
  otn: string,
): Promise<{ success: true; methods: CustomerReturnMethod[] }> =>
  request(`${OUTVIO_API_URL}/return/${otn}/allowed-methods`, {
    method: 'GET',
    secure: true,
  });
