import { OVLocalCache } from 'outvio-ui';
const mod = 'OV-check-localstorage';

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function hasNativeLocalStorage() {
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

class StorageMock {
  _values = new Map();

  constructor() {
    console.log('init fake localStorage');
  }

  async set(key: string, val: any): Promise<any> {
    this._values.set(key, val);
    return val;
  }

  async get(key: string): Promise<null | any> {
    return this._values.get(key) || null;
  }

  async delete(key: string): Promise<boolean> {
    this._values.delete(key);
    return true;
  }

  async purgeNonCache(): Promise<boolean> {
    this._values.clear();
    return true;
  }

  async purgeExpired(): Promise<boolean> {
    this._values.clear();
    return true;
  }
}

const storage = hasNativeLocalStorage() ? OVLocalCache : new StorageMock();
export default storage;

export function checkLocalStorage() {
  return new Promise(async (resolve) => {
    try {
      await storage.set(mod, mod);
      await storage.delete(mod);
      return resolve(true);
    } catch (e) {
      console.log('checkLocalStorage', e);
      return resolve(false);
    }
  });
}
