import request from './request';

import { OrderData } from '../../types/general';

export const requestMyOrders = (params: { hostname: string }) =>
  request(`${OUTVIO_API_URL}/customer/history`, {
    method: 'GET',
    params,
    secure: true,
  });

export const requestOrders = (params: { hostname: string }) =>
  request(`${OUTVIO_API_URL}/customer/order`, {
    method: 'GET',
    params,
    secure: true,
  });

export const requestOrderByOtn = (otn: string): Promise<{ order: OrderData }> =>
  request(`${OUTVIO_API_URL}/customer/order/${otn}`, {
    method: 'GET',
    secure: true,
  });
