import request from './request';

export const insertPickupAddress = (data) =>
  request(`${OUTVIO_API_URL}/return/pickup-address`, {
    method: 'POST',
    data,
    secure: true,
  });

export const updateProfile = (id, data) =>
  request(`${OUTVIO_API_URL}/user/${id}`, { method: 'PUT', data, secure: true });

export const deleteUserAddress = (userId, addressId) =>
  request(`${OUTVIO_API_URL}/user/${userId}/address/${addressId}/delete`, {
    method: 'DELETE',
    secure: true,
  });
