import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { OVCAlert, OVCAuthMakeReturnCTA, OVCAuthEmailInput } from 'outvio-ui';

import { requestLoginWithEmail } from '../../../utils/api';

const FormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const LoginBoxIdentity = () => {
  const location = useLocation();

  const [error, setError] = useState<null | string>(null);
  const [emailSent, setEmailSent] = useState(false);

  return (
    <Formik
      validationSchema={FormSchema}
      validateOnChange={false}
      initialValues={{
        email: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        requestLoginWithEmail({ email: values.email, language: location.pathname.split('/')[1] })
          .then(() => {
            setEmailSent(true);
            setSubmitting(false);
          })
          .catch((err) => {
            setError(err.message || err.toString());
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <OVCAuthEmailInput
            name="email"
            onChange={handleChange}
            value={values.email}
            disabled={isSubmitting}
            required
          />
          <OVCAuthMakeReturnCTA
            type="submit"
            disabled={isSubmitting}
            showLoader={isSubmitting}
            fullWidth
            style={{ marginTop: '8px' }}
          />
          <OVCAlert isOpen={error !== null}>{error}</OVCAlert>
          {emailSent && <FormattedMessage id="landing.email-sent" tagName="p" />}
        </form>
      )}
    </Formik>
  );
};

export default LoginBoxIdentity;
