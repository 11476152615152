import { requestTrackingDataMultiOrder } from 'utils/api';
import getISOLangFromParam from 'utils/getISOLangFromParam';
import { CustomerSupportedLanguage2Letter } from 'outvio-ui/lib/types/CustomerGeneral';
import { fiveMinutesCache } from 'utils/constants';
import { useQuery } from 'react-query';
import { useMemo } from 'react';

interface UseTrackingData {
  otn?: string;
  isSplit?: boolean;
}

export const useSplitOrder = ({ otn = '', isSplit }: UseTrackingData) => {
  const trackingLanguage = getISOLangFromParam(
    (window.location.pathname.split('/')[1] as CustomerSupportedLanguage2Letter) || 'en',
  );

  const { data, isLoading, error } = useQuery({
    queryKey: ['useDataSplit', otn],
    queryFn: () =>
      requestTrackingDataMultiOrder({
        otn,
        trackingLanguage,
      }),
    enabled: Boolean(otn) && Boolean(isSplit),
    cacheTime: fiveMinutesCache,
    staleTime: fiveMinutesCache,
  });

  return useMemo(() => ({ error, data, isLoading }), [error, data, isLoading]);
};
