import { useState, useEffect, useRef, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import {
  OVCLoader,
  OVCAlert,
  OVCStoreDataContext,
  OVCButton,
  OVCReturnCartHeading,
} from 'outvio-ui';
import OVLocalCache from 'utils/storage';
import { CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';
import { useHistory } from 'react-router-dom';
import * as io from 'socket.io-client';

import { makeStyles, Theme } from '@material-ui/core';

import {
  returnToClientShop,
  deletePackage,
  requestReturnPrint,
  ReturnCourierMethod,
  CloseReturnPackageParams,
} from '../../utils/api';
import getUrlParamFromLocale from '../../utils/getUrlParamFromLocale';
import PrintModal from '../../components/PrintModal';

import { StateProduct } from './returnCartTypes';
import { OrderData } from '../../types/general';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '260px',
    color: (props) => props.textColor,

    '& > p': {
      marginBottom: 0,
    },
  },
  interContainer: {
    width: '100%',
    minHeight: '300px',
    padding: '24px 50px',

    textAlign: 'center',
    color: (props) => props.textColor,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& h2': {
      fontSize: '18px',
      marginBottom: '16px',
    },
  },
  action: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linkStyle: {
    fontWeight: 600,
    textDecoration: 'underline',

    color: (props) => props.textColor,
    '&:hover': {
      color: (props) => props.textColor,
    },
  },
});

interface IConfirmationToClientShop {
  refundMethod: null | CustomerReimbursement;
  productsReturning: StateProduct[];
  order: OrderData;
}

const ConfirmationToClientShop = ({
  order,
  productsReturning,
  refundMethod,
}: IConfirmationToClientShop) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });
  const history = useHistory();
  const intl = useIntl();
  const socketRef = useRef<SocketIOClient.Socket>();

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [labels, setLabels] = useState<string[]>([]);
  const [areLabelsOpen, setLabelsOpen] = useState(false);
  const processFinished = useRef(false);

  useEffect(() => {
    socketRef.current = io.connect(OUTVIO_API_URL, {
      transports: ['websocket'],
      reconnectionDelayMax: 30000,
    });

    const handlePrint = () => {
      const newReturnProducts = productsReturning
        .filter((p) => p.stateSelected)
        .map((p) => ({
          _id: p._id,
          reason: p.returned.client.reason || 'NOT_DEFINED',
          images: p.returned.client.images,
          comment: p.returned.client.comment || '',
        }));

      const originalShippingPackage = order.shipping.packages.shipping.find((pack) =>
        pack.products.find((p) => p._id === newReturnProducts[0]._id),
      );

      const sendData: CloseReturnPackageParams = {
        method: ReturnCourierMethod.TO_CLIENT_SHOP,
        reimbursement: refundMethod || 'SAME_AS_PAYMENT',
        order: order._id,
        pickupAddress: order.shipping.shipments.shipping[0].delivery._id,
        withPickup: null,
        preparedPackages: [
          {
            products: newReturnProducts,
            ...(originalShippingPackage?.package
              ? { package: originalShippingPackage?.package || 'NOT_DEFINED' }
              : {
                  packaging: {
                    length: originalShippingPackage?.packaging.length || 1,
                    width: originalShippingPackage?.packaging.width || 1,
                    height: originalShippingPackage?.packaging.height || 1,
                  },
                }),
          },
        ],
      };

      const retPacksWithProdsThisReturn = order.shipping.packages.return.filter((pack) =>
        pack.products.some((prod) => {
          if (typeof prod === 'string') {
            return Boolean(newReturnProducts.find((p) => p._id === prod));
          } else {
            return Boolean(newReturnProducts.find((p) => p._id === prod._id));
          }
        }),
      );

      const cleanupPromise =
        retPacksWithProdsThisReturn.length > 0
          ? Promise.all(retPacksWithProdsThisReturn.map((pack) => deletePackage(pack._id)))
          : Promise.resolve(true);

      cleanupPromise
        .then(() => returnToClientShop(sendData))
        .then(() =>
          requestReturnPrint({
            orderId: order._id,
            reimbursement: refundMethod || 'SAME_AS_PAYMENT',
          }),
        )
        .catch((err) => {
          setError(err.message || err.toString());
          setLoading(false);
        });
    };

    if (socketRef.current !== null) {
      socketRef.current.on('connect', () => {
        if (processFinished.current === false) {
          handlePrint();
        }
      });

      socketRef.current.on('authentication-failed', (resp: { error: string }) => {
        setError(resp.error);
        setLoading(false);
      });

      socketRef.current.on('shipment-start-processing', () => {
        // data: { otn }
      });

      socketRef.current.on(
        'order-processing-error',
        (data: { error: string; isCourierError: boolean }) => {
          // data: { otn, error }
          if (data.isCourierError) {
            setError(
              intl.formatMessage({ id: 'ovc.return-cart.socket-error' }, { error: data.error }),
            );
          } else {
            setError(data.error.toString());
          }

          setLoading(false);
          processFinished.current = true;
        },
      );

      socketRef.current.on('mail-sending-error', (data: any) => {
        // noop
        console.log(data);
      });

      socketRef.current.on('order-processing-finish', (data: { urls: string[] }) => {
        // data: { otn, ...printResults }
        setLabels(data.urls);
        setLoading(false);
        processFinished.current = true;
      });

      socketRef.current.on('request-authentication', () => {
        OVLocalCache.get('token').then((token) => {
          if (socketRef.current) {
            socketRef.current.emit('authentication', {
              token,
              customer: true,
            });
          }
        });
      });
    } else {
      setError('Failed to connect to socket.');
      setLoading(false);
    }
  }, []);

  const handleFinish = () => {
    history.push(`/${getUrlParamFromLocale(intl.locale)}/my-orders`);
  };

  const returnableUntil = moment
    .utc(order.deliverDate)
    .add(storeData.return.days, 'days')
    .format('DD.MM.YYYY');

  if (isLoading) {
    return (
      <div className={classes.interContainer}>
        <OVCReturnCartHeading textId="retcart.print.loading.heading" />
        <OVCLoader />
        <p style={{ marginTop: '16px' }}>
          {intl.formatMessage({ id: 'retcart.print.loading.note' })}
        </p>
      </div>
    );
  }

  if (!isLoading && error !== null) {
    return (
      <div className={classes.interContainer}>
        <OVCReturnCartHeading textId="retcart.print.loading.heading" />
        <OVCAlert>{error}</OVCAlert>

        <OVCButton
          onClick={() => {
            window.location.reload();
          }}
          style={{ marginTop: '20px' }}
        >
          {intl.formatMessage({ id: 'general.retcart.restart.btn' })}
        </OVCButton>
      </div>
    );
  }
  return (
    <>
      <OVCReturnCartHeading textId="retcart.confirm.heading" />
      <div className={classes.container}>
        <p>
          <span style={{ fontWeight: 600 }}>
            <FormattedMessage id="retcart.confirm.outvio.head-sheet" />
          </span>
          <br />
          <FormattedMessage id="retcart.confirm.outvio.head-sheet.note" />
        </p>
        <br />
        <OVCButton onClick={() => setLabelsOpen(true)}>
          {intl.formatMessage({ id: 'retcart.download-return-sheet' })}
        </OVCButton>
        <br />
        <p style={{ fontWeight: 600 }}>
          <FormattedMessage id="retcart.confirm.text3" />
        </p>
        <FormattedMessage id="retcart.confirm.toClientShop.text4" tagName="p" />
        <FormattedMessage
          id="retcart.confirm.toClientShop.text5"
          values={{
            dueDate: returnableUntil,
            fillerShop: (
              <a
                href={storeData.return.toClientShopUrl}
                target="_blank"
                className={classes.linkStyle}
              >
                <FormattedMessage id="retcart.confirm.toClientShop.text5.fillerShop" />
              </a>
            ),
          }}
          tagName="p"
        />
        <FormattedMessage id="retcart.confirm.toClientShop.text6" tagName="p" />
      </div>
      <div className={classes.action}>
        <OVCButton onClick={handleFinish}>{intl.formatMessage({ id: 'general.done' })}</OVCButton>
      </div>
      {areLabelsOpen && <PrintModal onClose={() => setLabelsOpen(false)} labels={labels} />}
    </>
  );
};

export default ConfirmationToClientShop;
