import { UseQueryOptions, useQuery } from 'react-query';

type SourceType = 'cache' | 'fresh';

interface UseCacheRequestReturns<T> {
  source: SourceType;
  data: T | undefined;
  error: Error | null;
  isLoading: boolean;
}

interface UseCacheRequestProps<T> {
  freshRequest: UseQueryOptions<T, Error>;
  cacheRequest: UseQueryOptions<T, Error>;
}

/**
 * Use when you want to perform a request and display the value.
 * Then you perform a second request. If the second request is succesful,
 * you display the second value, if not, you keep displaying the first one.
 */
export const useCacheRequest = <T>({
  freshRequest,
  cacheRequest,
}: UseCacheRequestProps<T>): UseCacheRequestReturns<T> => {
  const {
    data: cacheData,
    isLoading: cacheIsLoading,
    error: cacheError,
  } = useQuery({
    ...cacheRequest,
  });
  const {
    data: freshData,
    error: freshError,
    isLoading: freshIsLoading,
  } = useQuery({
    ...freshRequest,
  });

  return {
    data: freshData || cacheData,
    source: freshData ? 'fresh' : 'cache',
    error: cacheError || (!cacheData && freshError ? freshError : null),
    isLoading: cacheIsLoading || (!cacheData && freshIsLoading),
  };
};
