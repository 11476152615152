import { CustomerSupportedLanguage2Letter } from 'outvio-ui/lib/types/CustomerGeneral';

const getUrlParamFromLocale = (locale: string): CustomerSupportedLanguage2Letter => {
  switch (locale) {
    case 'en-EN':
      return 'en';
    case 'es-ES':
      return 'es';
    case 'et-EE':
      return 'et';
    case 'fr-FR':
      return 'fr';
    case 'it-IT':
      return 'it';
    case 'de-DE':
      return 'de';
    case 'fi-FI':
      return 'fi';
    case 'lt-LT':
      return 'lt';
    case 'lv-LV':
      return 'lv';
    case 'pt-PT':
      return 'pt';
    case 'pl-PL':
      return 'pl';
    case 'ca-ES':
      return 'ca';
    case 'nl-NL':
      return 'nl';
    case 'bg-BG':
      return 'bg';
    case 'hr-HR':
      return 'hr';
    case 'cs-CZ':
      return 'cs';
    case 'el-GR':
      return 'el';
    case 'hu-HU':
      return 'hu';
    case 'ga-IE':
      return 'ga';
    case 'ro-RO':
      return 'ro';
    case 'sk-SK':
      return 'sk';
    case 'sl-SI':
      return 'sl';
    case 'no-NO':
      return 'no';
    case 'sv-SE':
      return 'sv';
    case 'da-DK':
      return 'da';
    default:
      return 'en';
  }
};

export default getUrlParamFromLocale;
