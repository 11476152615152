import { useContext, useMemo, useCallback } from 'react';
import { OVCStoreDataContext, opacityToSolidColor } from 'outvio-ui';
import cn from 'classnames';
import { Address } from 'outvio-ui/lib/types/Address';

import { makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

interface StyleProps {
  bgColor: string;
  activeBg: string;
  textColor: string;
  tenPctText: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    position: 'relative',
    width: '100%',
    padding: '15px',

    borderRadius: '5px',
    border: (props) => `1px solid ${props.tenPctText}`,
    backgroundColor: (props) => props.bgColor,

    color: (props) => props.textColor,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '22px',

    userSelect: 'none',
    cursor: 'pointer',

    '& > span:first-child': {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    '& > span:nth-child(2)': {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      display: '-webkit-box',
      overflowWrap: 'break-word',
      WebkitBoxOrient: 'vertical',
    },
  },
  containerActive: {
    backgroundColor: (props) => props.activeBg,
    borderColor: (props) => props.textColor,
  },
  deleteHolder: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    display: 'flex',

    color: (props) => props.textColor,
    fontSize: '17px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface IReturnCartAddressBox {
  address: Address;
  isActive: boolean;
  onSelect(addressId: string): void;
  onRequestDelete(addressId: string): void;
  disableDelete: boolean;
}

const ReturnCartAddressBox = ({
  address,
  isActive,
  onSelect,
  onRequestDelete,
  disableDelete,
}: IReturnCartAddressBox) => {
  const { storeData } = useContext(OVCStoreDataContext);
  const tenPctText = useMemo(
    () =>
      opacityToSolidColor(
        storeData.portalSettings.colors.primaryText,
        0.1,
        storeData.portalSettings.colors.secondaryBg,
      ),
    [storeData],
  );
  const classes = useStyles({
    bgColor: storeData.portalSettings.colors.secondaryBg,
    activeBg: storeData.portalSettings.colors.primaryBg,
    textColor: storeData.portalSettings.colors.primaryText,
    tenPctText,
  });

  const handleSelect = useCallback(() => onSelect(address._id), [onSelect, address]);
  const handleRequestDelete = useCallback(
    (e) => {
      e.stopPropagation();
      onRequestDelete(address._id);
    },
    [onRequestDelete, address],
  );

  return (
    <div
      className={cn(classes.container, { [classes.containerActive]: isActive })}
      onClick={handleSelect}
    >
      <span>{address.name}</span>
      <span>
        {address.address}, {address.city}
      </span>
      {!disableDelete && (
        <div className={classes.deleteHolder} onClick={handleRequestDelete}>
          <DeleteIcon color="inherit" fontSize="inherit" />
        </div>
      )}
    </div>
  );
};

export default ReturnCartAddressBox;
