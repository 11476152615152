import { useState, useEffect, useRef, useContext } from 'react';
import isEqual from 'lodash-es/isEqual';
import * as io from 'socket.io-client';
import {
  OVCAlert,
  OVCLoader,
  OVCStoreDataContext,
  OVCReturnCartHeading,
  OVCButton,
} from 'outvio-ui';
import OVLocalCache from 'utils/storage';
import { CustomerReimbursement } from 'outvio-ui/lib/types/CustomerStoreData';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core';

import { insertPickupAddress, requestReturnPrint } from '../../utils/api';
import getUrlParamFromLocale from '../../utils/getUrlParamFromLocale';
import getCourierPickupLocation from '../../utils/getCourierPickupLocation';
import PrintModal from '../../components/PrintModal';
import apiErrors from 'outvio-ui/src/i18n-customer/apiErrors';

import { Package, ReturnLabelMode, ReturnAddress } from './returnCartTypes';
import { SupportedLanguage, OrderData } from '../../types/general';

interface StyleProps {
  textColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    width: '100%',
    minHeight: '260px',
    color: (props) => props.textColor,

    '& > p': {
      marginBottom: 0,
    },
  },
  interContainer: {
    width: '100%',
    minHeight: '300px',

    textAlign: 'center',
    color: (props) => props.textColor,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& h2': {
      fontSize: '18px',
      marginBottom: '16px',
    },
  },
  action: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface IReturnCartStep4 {
  refundMethod: null | CustomerReimbursement;
  hasPickup: boolean;
  labelMode: ReturnLabelMode;
  order: OrderData;
  returnPackages: Package[];
  returnAddress: ReturnAddress;
}

const ReturnCartStep4 = ({
  order,
  returnAddress,
  returnPackages,
  hasPickup,
  labelMode,
  refundMethod,
}: IReturnCartStep4) => {
  const { storeData } = useContext(OVCStoreDataContext);

  const classes = useStyles({ textColor: storeData.portalSettings.colors.primaryText });
  const history = useHistory();
  const intl = useIntl();
  const socketRef = useRef<SocketIOClient.Socket>();

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [labels, setLabels] = useState<string[]>([]);
  const [areLabelsOpen, setLabelsOpen] = useState(false);
  const processFinished = useRef(false);

  const handleFinish = () => {
    history.push(`/${getUrlParamFromLocale(intl.locale)}/my-orders`);
  };

  useEffect(() => {
    socketRef.current = io.connect(OUTVIO_API_URL, {
      transports: ['websocket'],
      reconnectionDelayMax: 30000,
    });

    const shouldUpdatePickupAddress = !isEqual(
      order.shipping.shipments.shipping[0].delivery,
      returnAddress,
    );

    const handlePrint = () => {
      Promise.resolve()
        .then(() => {
          if (shouldUpdatePickupAddress) {
            // do that
            return insertPickupAddress({
              pickupAddress: {
                ...returnAddress,
                pickupDate: moment().format('DD/MM/YYYY'),
                pickupTime: {
                  from: '08:00',
                  to: '20:00',
                },
              },
              packageIds: returnPackages.map((pack) => pack._id),
            });
          } else {
            return Promise.resolve();
          }
        })
        .then(() => {
          return requestReturnPrint({
            orderId: order._id,
            reimbursement: refundMethod || 'SAME_AS_PAYMENT',
          });
        })
        .catch((err) => {
          setError(err.message || err.toString());
          setLoading(false);
        });
    };

    if (socketRef.current !== null) {
      socketRef.current.on('connect', () => {
        if (processFinished.current === false) {
          handlePrint();
        }
      });

      socketRef.current.on('authentication-failed', (resp: { error: string }) => {
        setError(resp.error);
        setLoading(false);
      });

      socketRef.current.on(
        'order-processing-error',
        (data: { error: string; isCourierError: boolean }) => {
          const lang = intl.locale as SupportedLanguage;
          if (data.isCourierError) {
            setError(
              intl.formatMessage({ id: 'ovc.return-cart.socket-error' }, { error: data.error }),
            );
          } else if (apiErrors[lang] && apiErrors[lang][data.error]) {
            setError(data.error);
          } else {
            setError(data.error);
          }
          setLoading(false);
          processFinished.current = true;
        },
      );

      socketRef.current.on('mail-sending-error', (data: any) => {
        // noop
        console.log(data);
      });

      socketRef.current.on('order-processing-finish', (data: { urls: string[] }) => {
        if (!Array.isArray(data.urls) || !data.urls.every((u) => typeof u === 'string')) {
          setError('Incorrect label response.');
          setLoading(false);
          processFinished.current = true;
        } else {
          setLabels(data.urls);
          setLoading(false);
          processFinished.current = true;
        }
      });

      socketRef.current.on('request-authentication', () => {
        OVLocalCache.get('token').then((token) => {
          if (socketRef.current) {
            socketRef.current.emit('authentication', {
              token,
              customer: true,
            });
          }
        });
      });
    } else {
      setError('Failed to connect to socket.');
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={classes.interContainer}>
        <OVCReturnCartHeading textId="retcart.print.loading.heading" />

        <OVCLoader />
        <p style={{ marginTop: '16px' }}>
          {intl.formatMessage({ id: 'retcart.print.loading.note' })}
        </p>
      </div>
    );
  }

  if (!isLoading && error !== null) {
    return (
      <div className={classes.interContainer}>
        <OVCReturnCartHeading textId="retcart.print.loading.heading" />
        <OVCAlert>{error}</OVCAlert>

        <OVCButton
          onClick={() => {
            window.location.reload();
          }}
          style={{ marginTop: '20px' }}
        >
          {intl.formatMessage({ id: 'general.retcart.restart.btn' })}
        </OVCButton>
      </div>
    );
  }

  return (
    <>
      <OVCReturnCartHeading textId="retcart.confirm.heading" />
      <div className={classes.container}>
        {labelMode === 'provided' && (
          <>
            <p>
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage id="retcart.confirm.outvio.head-label" />
              </span>
              <br />
              <FormattedMessage id="retcart.confirm.outvio.head-label.note" />
            </p>
            <br />
            <OVCButton onClick={() => setLabelsOpen(true)}>
              {intl.formatMessage({ id: 'retcart.download-label' })}
            </OVCButton>
          </>
        )}
        {labelMode === 'noLabel' && (
          <>
            <p>
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage id="retcart.confirm.outvio.head-sheet" />
              </span>
              <br />
              <FormattedMessage id="retcart.confirm.outvio.head-sheet.note" />
            </p>
            <br />
            <OVCButton onClick={() => setLabelsOpen(true)}>
              {intl.formatMessage({ id: 'retcart.download-return-sheet' })}
            </OVCButton>
          </>
        )}
        {labelMode === 'byEmail' && (
          <>
            <p>
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage id="retcart.confirm.outvio.head-label-by-email" />
              </span>
            </p>
          </>
        )}

        <br />
        {hasPickup && labelMode === 'noLabel' && (
          <>
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.hasPickup.noLabel.head" />
            </p>
            <br />
            <FormattedMessage
              id="retcart.confirm.outvio.hasPickup.noLabel"
              tagName="p"
              values={{ lineBreak: <br /> }}
            />
          </>
        )}
        {hasPickup && labelMode === 'byEmail' && (
          <>
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.hasPickup.byEmail.head" />
            </p>
            <br />
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.hasPickup.noLabel.head" />
            </p>
            <br />
            <FormattedMessage
              id="retcart.confirm.outvio.hasPickup.byEmail"
              tagName="p"
              values={{ lineBreak: <br /> }}
            />
          </>
        )}
        {hasPickup && labelMode === 'provided' && (
          <>
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.hasPickup.noLabel.head" />
            </p>
            <br />
            <FormattedMessage
              id="retcart.confirm.outvio.hasPickup.provided"
              tagName="p"
              values={{ lineBreak: <br /> }}
            />
          </>
        )}
        {!hasPickup && labelMode === 'byEmail' && (
          <>
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.hasPickup.byEmail.head" />
            </p>
            <br />
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.noPickup.noLabel.head" />
            </p>
            <br />
            <FormattedMessage
              id="retcart.confirm.outvio.noPickup.byEmail"
              tagName="p"
              values={{
                lineBreak: <br />,
                filler: getCourierPickupLocation(
                  returnPackages,
                  'retcart.confirm.outvio.noPickup.byEmail.filler',
                ),
              }}
            />
          </>
        )}
        {!hasPickup && labelMode === 'provided' && (
          <>
            <p style={{ fontWeight: 600 }}>
              <FormattedMessage id="retcart.confirm.outvio.noPickup.noLabel.head" />
            </p>
            <br />
            <FormattedMessage
              id="retcart.confirm.outvio.noPickup.provided"
              tagName="p"
              values={{
                lineBreak: <br />,
                filler: getCourierPickupLocation(
                  returnPackages,
                  'retcart.confirm.outvio.noPickup.provided.filler',
                ),
              }}
            />
          </>
        )}
      </div>
      <div className={classes.action}>
        <OVCButton onClick={handleFinish}>{intl.formatMessage({ id: 'general.done' })}</OVCButton>
      </div>
      {areLabelsOpen && <PrintModal onClose={() => setLabelsOpen(false)} labels={labels} />}
    </>
  );
};

export default ReturnCartStep4;
