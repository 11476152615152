import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { OVCLoader, OVCAlert } from 'outvio-ui';
import OVLocalCache from 'utils/storage';
import { userLoaded } from '../../slices/user';
import { requestUser } from '../../utils/api';
import getUrlParamFromLocale from '../../utils/getUrlParamFromLocale';

const IdentityVerificationPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isInitializing, setIsInitializing] = useState(true);
  const [initError, setInitError] = useState<null | string>(null);

  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');

      if (!token) {
        setInitError(intl.formatMessage({ id: 'general.error.id-ver.token-missing' }));
        setIsInitializing(false);
      } else {
        OVLocalCache.set('token', token)
          .then(() => requestUser())
          .then((res) => dispatch(userLoaded(res.user)))
          .then(() => {
            setIsInitializing(false);
            window.location.assign(`/${getUrlParamFromLocale(intl.locale)}/my-orders`);
          })
          .catch((err) => {
            setInitError(err.message || err.toString());
            setIsInitializing(false);
          });
      }
    } catch (err) {
      setInitError(intl.formatMessage({ id: 'general.error.id-ver.generic' }));
      setIsInitializing(false);
    }
  }, []);

  if (isInitializing) {
    return <OVCLoader />;
  }

  if (initError !== null) {
    return <OVCAlert>{initError}</OVCAlert>;
  }

  return <OVCLoader />;
};

export default IdentityVerificationPage;
